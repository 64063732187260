import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, TextField, Typography, Tooltip } from "@material-ui/core";
import moment from "moment";

import { useDebouncedEffect } from "@hooks/debounceEffect";
import { useStore } from "@store/store";
import {
  ASSIGN_DRIVER_JOB_POOL,
  ASSIGN_DRIVER_JOB_POOL_FAILURE,
  ASSIGN_DRIVER_JOB_POOL_PROGRESS_FAILURE,
  ASSIGN_DRIVER_JOB_POOL_PROGRESS_SUCCESS,
  ASSIGN_DRIVER_JOB_POOL_SUCCESS,
  FETCH_JOB_POOL,
  FETCH_JOB_POOL_FAILURE,
  FETCH_JOB_POOL_SUCCESS,
  RESET_REDIRECTION,
} from "@utils/actionTypes";

import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";

import Header from "@components/header";
import searchIcon from "@assets/images/search.svg";
import deployedCodeAccount from "@assets/images/deployed-code-account-white.svg";
import API from "@services/axios";
import { getFilter, removeFilter, setFilter } from "@utils/commonFunctions";
import { rowsPerPageVal } from "@utils/constant";
import { JobPoolListingStyle } from "./style";

import AssignDriverPopup from "./assign-driver-popup";
import Filter from "./filter";
import TableListing from "./table-listing";
import { useFormik } from "formik";
import { toast } from "react-toastify";

function JobPoolListing() {
  const classes = JobPoolListingStyle();
  const dashboardFilter = getFilter("jobDashboardFilter", true);
  const filter = getFilter("jobPoolFilter", true);
  const cargoVolumeFilter = getFilter("cargoVolume");
  let status = "";
  const [state, dispatch] = useStore();
  const [openFilter, setOpenFilter] = useState(false);
  const [dataTable, setDataTable] = useState({});
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedCityArray, setSelectedCityArray] = useState([]);
  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [period, setPeriod] = useState(
    filter ? filter?.period : dashboardFilter ? dashboardFilter.period : "",
  );
  const [openStartJob, setOpenStartJob] = useState(false);
  const [showStartJob, setShowStartJob] = useState(false);
  const [startJobPeriod, setStartJobPeriod] = useState(
    filter ? filter?.startJobPeriod : "",
  );
  const isFirstRender = useRef(true);

  status = state?.redirection?.jobStatusRedirection
    ? state?.common?.jobStatusData?.find(
        (item) => item.name === state?.redirection?.jobStatusRedirection,
      ).id
    : "";
  const [filterData, setFilterData] = useState({
    customer: filter
      ? filter?.customer
      : dashboardFilter
        ? dashboardFilter.customer
        : [],
    jobType: filter
      ? filter?.jobType
      : dashboardFilter
        ? dashboardFilter.jobType
        : [],
    jobStatus: filter ? filter.jobStatus : [],
    city: filter ? filter?.city : dashboardFilter ? dashboardFilter.city : [],
    cargoType: cargoVolumeFilter
      ? cargoVolumeFilter
      : filter
        ? filter.cargoType
        : dashboardFilter
          ? dashboardFilter.cargoType
          : [],
    cto: filter ? filter?.cto : dashboardFilter ? dashboardFilter.cto : [],
    startDate: filter
      ? filter?.startDate
      : dashboardFilter
        ? dashboardFilter.startDate
        : "",
    endDate: filter
      ? filter?.endDate
      : dashboardFilter
        ? dashboardFilter.endDate
        : "",
    createJobStartDate: filter ? filter?.createJobStartDate : "",
    createJobEndDate: filter ? filter?.createJobEndDate : "",
  });
  const [customDate, setCustomDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [startJobCustomDate, setStartJobCustomDate] = useState({
    createJobStartDate: null,
    createJobEndDate: null,
  });

  // Handle Assign Driver Popup
  const [openDriverPopup, setOpenDriverPopup] = useState(false);
  const [error, setError] = useState("");

  const handleAssignDriverPopupOpen = () => {
    assignDriverFormik.values.assignDriver = "";
    setOpenDriverPopup(true);
  };

  const handleAssignDriverPopupClose = () => {
    setOpenDriverPopup(false);
    assignDriverFormik.touched.assignDriver = false;
    setError("");
  };

  useEffect(() => {
    const totalJobs = selectedArray.length;
    const { assigningDriverProgressSuccess, assigningDriverProgressFailure } =
      state.jobPool;
    const processingJobs =
      assigningDriverProgressSuccess + assigningDriverProgressFailure;
    const hasAnyError = assigningDriverProgressFailure > 0;

    if (processingJobs > 0 && totalJobs === processingJobs) {
      handleAssignDriverPopupClose();
      setSelectedArray([]);
      if (hasAnyError) {
        if (assigningDriverProgressFailure === totalJobs) {
          dispatch({ type: ASSIGN_DRIVER_JOB_POOL_FAILURE, payload: {} });
          toast.error("Driver Assigned Unsuccessful");
        } else {
          dispatch({
            type: ASSIGN_DRIVER_JOB_POOL_SUCCESS,
            payload: {},
          });
          toast.warning("Driver Assigned Successfully with errors");
          getJobPool();
        }
      } else {
        dispatch({
          type: ASSIGN_DRIVER_JOB_POOL_SUCCESS,
          payload: {},
        });
        toast.success("Driver Assigned Successfully");
        getJobPool();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.jobPool.assigningDriverProgressSuccess,
    state.jobPool.assigningDriverProgressFailure,
  ]);

  const assignDriverFormik = useFormik({
    initialValues: schema.assignDriverSchema,
    validationSchema: validationSchema.assignDriverValidationSchema,
    onSubmit: (value) => {
      dispatch({ type: ASSIGN_DRIVER_JOB_POOL });
      selectedArray.forEach((jobId) => {
        API.put(`jobPool/${jobId}/assignDriver`, {
          driverId: value.assignDriver,
        })
          .then((response) => {
            dispatch({
              type: ASSIGN_DRIVER_JOB_POOL_PROGRESS_SUCCESS,
            });
          })
          .catch((error) => {
            dispatch({
              type: ASSIGN_DRIVER_JOB_POOL_PROGRESS_FAILURE,
            });
          });
      });
    },
  });

  const params = useMemo(() => {
    return {
      page: page + 1,
      size: rowsPerPage,
      ...(!!debounceSearch ? { search: debounceSearch } : {}),
      order: order !== "" ? order : "desc",
      orderBy: orderBy !== "" ? orderBy : "id",

      filter: {
        ...(!!filterData.jobType.length
          ? { jobTypeId: filterData.jobType }
          : {}),
        ...(!!filterData.jobStatus.length
          ? { jobStatusId: filterData.jobStatus }
          : {}),
        ...(!!filterData.city.length ? { cityId: filterData.city } : {}),
        ...(!!filterData.customer.length
          ? { customerId: filterData.customer }
          : {}),
        ...(!!filterData.cargoType.length
          ? { cargoTypeId: filterData.cargoType }
          : {}),
        ...(!!filterData.cto.length ? { ctoId: filterData.cto } : {}),
        ...(!!filterData.startDate
          ? { startDate: filterData.startDate }
          : !!customDate.startDate
            ? { startDate: filterData.startDate }
            : {}),
        ...(!!filterData.endDate
          ? { endDate: filterData.endDate }
          : !!customDate.endDate
            ? { endDate: filterData.endDate }
            : {}),
        ...(!!filterData.createJobStartDate
          ? { createJobStartDate: filterData.createJobStartDate }
          : !!startJobCustomDate.createJobStartDate
            ? { createJobStartDate: filterData.createJobStartDate }
            : {}),
        ...(!!filterData.createJobEndDate
          ? { createJobEndDate: filterData.createJobEndDate }
          : !!startJobCustomDate.createJobEndDate
            ? { createJobEndDate: filterData.createJobEndDate }
            : {}),
      },
    };
  }, [
    page,
    rowsPerPage,
    debounceSearch,
    order,
    orderBy,
    filterData,
    customDate,
    startJobCustomDate,
  ]);

  // API calling to get list of jobs Pool
  let getJobPool = () => {
    dispatch({ type: FETCH_JOB_POOL });
    API.get("jobPool", { params })
      .then((response) => {
        dispatch({
          type: FETCH_JOB_POOL_SUCCESS,
          payload: response.data.data,
        });

        setDataTable(response?.data?.data?.rows);
      })
      .catch((error) => {
        dispatch({ type: FETCH_JOB_POOL_FAILURE, payload: error });
      });
  };

  useEffect(() => {
    if (state?.redirection?.jobStatusRedirection && status) {
      setFilterData({
        ...filterData,
        jobStatus: [
          state?.common?.jobStatusData?.find(
            (item) => item.name === state?.redirection?.jobStatusRedirection,
          ).id,
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    getJobPool();
    setFilter("jobPoolFilter", { ...filterData, period, startJobPeriod }, true);
    return () =>
      dispatch({
        type: RESET_REDIRECTION,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    state?.redirection?.filterRedirection,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(0);
    if (page === 0) {
      getJobPool();
    }
  }, [filterData, debounceSearch]);

  // Debounce the search after 1 second
  useDebouncedEffect(
    () => {
      setDebounceSearch(search);
    },
    1000,
    [search],
  );

  useEffect(() => {
    const cityMap = new Map();
    selectedArray.forEach((jobId) => {
      const job = dataTable.find((job) => job.id === jobId);
      cityMap.set(job.cities.id, job.cities.name);
    });

    setSelectedCityArray([...cityMap.keys()]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArray]);

  const openFilterPopup = () => {
    document.body.classList.toggle("open-filter");
    setOpenFilter(true);
  };

  const closeFilterPopup = () => {
    document.body.classList.remove("open-filter");
    setOpenFilter(false);
  };

  const handleReset = () => {
    removeFilter("jobPoolFilter");
    removeFilter("jobDashboardFilter");
    removeFilter("cargoVolume");
    document.body.classList.remove("open-filter");
    setShow(false);
    setPeriod("");
    setShowStartJob(false);
    setStartJobPeriod("");
    setFilterData({
      jobStatus: [],
      truckRego: [],
      driver: [],
      customer: [],
      jobType: [],
      city: [],
      cargoType: [],
      cto: [],
      startDate: null,
      endDate: null,
      createJobStartDate: null,
      createJobEndDate: null,
    });
    setCustomDate({
      startDate: null,
      endDate: null,
    });
    setStartJobCustomDate({
      createJobStartDate: null,
      createJobEndDate: null,
    });
  };

  const handleStartJobCustomDateSubmit = () => {
    setOpenStartJob(false);
    setShowStartJob(true);
    setFilterData({
      ...filterData,
      createJobStartDate: startJobCustomDate.createJobStartDate,
      createJobEndDate: startJobCustomDate.createJobEndDate,
    });
    setStartJobPeriod(7);
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });

    setFilter("jobPoolFilter", { ...filterData, period, startJobPeriod }, true);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  const handleSorting = (event, property) => {
    const isAsc = orderBy === property.sortTitle && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.sortTitle);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // To handle checkboxes from listing
  const handleChange = (item) => {
    let id = item.id;
    const selectedIndex = selectedArray.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedArray, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedArray.slice(1));
    } else if (selectedIndex === selectedArray.length - 1) {
      newSelected = newSelected.concat(selectedArray.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedArray.slice(0, selectedIndex),
        selectedArray.slice(selectedIndex + 1),
      );
    }

    setSelectedArray(newSelected);
  };

  // To handle main checkbox
  const handleMainChangeCheckBox = (e) => {
    if (e.target.checked) {
      const newSelected = dataTable.map((n) => n.id);
      setSelectedArray(newSelected);
      return;
    }
    setSelectedArray([]);
  };

  const handleStartJobPeriodChange = (event, type) => {
    const { value } = event.target;
    setStartJobPeriod(value);
    setShowStartJob(false);
    if (!openFilter) {
      setFilter(
        "jobPoolFilter",
        {
          ...filterData,
          startJobPeriod: value,
        },
        true,
      );
    }
    if (value === 7 || type === true) {
      setOpenStartJob(true);
    } else {
      const newDate = new Date();
      if (value === "") {
        setFilterData({
          ...filterData,
          createJobStartDate: null,
          createJobEndDate: null,
        });
        setStartJobCustomDate({
          createJobStartDate: null,
          createJobEndDate: null,
        });
      } else if (value === 1) {
        setFilterData({
          ...filterData,
          createJobStartDate: moment(newDate).format("YYYY-MM-DD"),
          createJobEndDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 2) {
        setFilterData({
          ...filterData,
          createJobStartDate: moment(newDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD"),
          createJobEndDate: moment(newDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD"),
        });
      } else if (value === 3) {
        setFilterData({
          ...filterData,
          createJobStartDate: moment(newDate)
            .subtract(3, "day")
            .format("YYYY-MM-DD"),
          createJobEndDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 4) {
        setFilterData({
          ...filterData,
          createJobStartDate: moment(newDate)
            .subtract(7, "day")
            .format("YYYY-MM-DD"),
          createJobEndDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 5) {
        setFilterData({
          ...filterData,
          createJobStartDate: moment(newDate)
            .subtract(14, "day")
            .format("YYYY-MM-DD"),
          createJobEndDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 6) {
        setFilterData({
          ...filterData,
          createJobStartDate: moment(newDate)
            .subtract(30, "day")
            .format("YYYY-MM-DD"),
          createJobEndDate: moment(newDate).format("YYYY-MM-DD"),
        });
      }
    }
  };

  const handlePeriodChange = (event, type) => {
    const { value } = event.target;
    setPeriod(value);
    setShow(false);
    if (!openFilter) {
      setFilter(
        "jobPoolFilter",
        {
          ...filterData,
          period: value,
        },
        true,
      );
    }
    if (value === 7 || type === true) {
      setOpen(true);
    } else {
      const newDate = new Date();
      if (value === "") {
        setFilterData({
          ...filterData,
          startDate: null,
          endDate: null,
        });
        setCustomDate({
          startDate: null,
          endDate: null,
        });
      } else if (value === 1) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 2) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(1, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).subtract(1, "day").format("YYYY-MM-DD"),
        });
      } else if (value === 3) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(3, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 4) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(7, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 5) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(14, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 6) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(30, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      }
    }
  };

  const handleClose = () => {
    setPeriod("");
    setOpen(false);
    setCustomDate({
      startDate: null,
      endDate: null,
    });
  };

  const handleStartJobClose = () => {
    setStartJobPeriod("");
    setOpenStartJob(false);
    setStartJobCustomDate({
      createJobStartDate: null,
      createJobEndDate: null,
    });
  };

  const handleCustomChange = (event, type) => {
    if (type === "startDate" || type === "endDate") {
      setCustomDate({
        ...customDate,
        [type]: moment(event).format("YYYY-MM-DD"),
      });
    } else {
      const { name, value } = event.target;
      setCustomDate({
        ...customDate,
        [name]: value,
      });
    }
  };

  const handleStartJobCustomChange = (event, type) => {
    if (type === "createJobStartDate" || type === "createJobEndDate") {
      setStartJobCustomDate({
        ...startJobCustomDate,
        [type]: moment(event).format("YYYY-MM-DD"),
      });
    } else {
      const { name, value } = event.target;
      setStartJobCustomDate({
        ...startJobCustomDate,
        [name]: value,
      });
    }
  };

  return (
    <>
      <Header />
      <div className={classes.JobPoolListingWrapper}>
        <div className="dashboard-page wrapper">
          <div className="inner-page">
            <div className={classes.innerPageTopBlock}>
              <div className="left-block">
                <Typography variant="h1">Job Pool</Typography>
              </div>

              <div className="right-block">
                <div className="right-block-inner">
                  <div className="search-wrapper">
                    <div className="form-group">
                      <TextField
                        id="search-request"
                        placeholder="Search by ID/AWB/Cons./ULD/Customer"
                        variant="outlined"
                        type="search"
                        InputProps={{
                          endAdornment: <img src={searchIcon} alt="Search" />,
                        }}
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <Tooltip
                    title="Select at least one job to enable the Assign button. If selecting multiple jobs, ensure they are all from the same city"
                    disableHoverListener={selectedCityArray.length === 1}
                  >
                    <div className="button_holder">
                      <div className="btn-wrapper">
                        <Button
                          color="inherit"
                          variant="contained"
                          disableElevation
                          className="primary-btn orange-btn"
                          onClick={() => handleAssignDriverPopupOpen()}
                          disabled={selectedCityArray.length !== 1}
                        >
                          <img src={deployedCodeAccount} alt="Assign" />
                          Assign
                        </Button>
                      </div>
                    </div>
                  </Tooltip>
                  <div className="dropdown_wrapper1">
                    <div className={classes.filter}>
                      <Filter
                        handleFilter={handleFilter}
                        filterData={filterData}
                        openFilterPopup={openFilterPopup}
                        closeFilterPopup={closeFilterPopup}
                        handleClose={handleClose}
                        handlePeriodChange={handlePeriodChange}
                        handleCustomChange={handleCustomChange}
                        period={period}
                        open={open}
                        show={show}
                        customDate={customDate}
                        startJobPeriod={startJobPeriod}
                        openStartJob={openStartJob}
                        showStartJob={showStartJob}
                        startJobCustomDate={startJobCustomDate}
                        handleStartJobPeriodChange={handleStartJobPeriodChange}
                        handleStartJobCustomChange={handleStartJobCustomChange}
                        handleStartJobCustomDateSubmit={
                          handleStartJobCustomDateSubmit
                        }
                        handleStartJobClose={handleStartJobClose}
                        handleReset={handleReset}
                        openFilter={openFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TableListing
              handleChange={handleChange}
              handleMainChange={handleMainChangeCheckBox}
              selectedArray={selectedArray}
              page={page}
              handleChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSorting={(e, property) => handleSorting(e, property)}
              orderBy={orderBy}
              order={order}
            />

            <AssignDriverPopup
              handleClose={handleAssignDriverPopupClose}
              open={openDriverPopup}
              error={error}
              cityId={selectedCityArray[0]}
              formik={assignDriverFormik}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobPoolListing;
