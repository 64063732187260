import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {TextField, Typography} from "@material-ui/core";
import { useFormik } from "formik";
import moment from "moment";

import { useStore } from "@store/store";
import Header from "@components/header";
import ProfileSideBar from "@components/profile-sidebar";
import IgnorePopup from "./confirm-popup";
import AddTruckPopup from "./popup";
import AlertPopup from "@components/alertPopup";
import {
  FETCH_UNMATCHED_VEHICLES_LIST,
  FETCH_UNMATCHED_VEHICLES_LIST_SUCCESS,
  FETCH_UNMATCHED_VEHICLES_LIST_FAILURE,
  ADD_UNMATCHED_VEHICLE,
  ADD_UNMATCHED_VEHICLE_SUCCESS,
  ADD_UNMATCHED_VEHICLE_FAILURE,
  IGNORE_UNMATCHED_VEHICLE,
  IGNORE_UNMATCHED_VEHICLE_SUCCESS,
  IGNORE_UNMATCHED_VEHICLE_FAILURE,
  GET_TRUCK_TYPE,
  GET_TRUCK_TYPE_SUCCESS,
  GET_TRUCK_TYPE_FAILURE,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE
} from "@utils/actionTypes";
import { schema } from "@utils/schemas";
import { rowsPerPageVal } from "@utils/constant";
import validationSchema from "@utils/validationSchemas";
import API from "@services/axios";
import TableListing from "./table-listing";
import { StaffUserStyle } from "./style";
import SearchIcon from "@assets/images/search.svg";
import { useDebouncedEffect } from "@hooks/debounceEffect";

function StaffUser() {
  const classes = StaffUserStyle();
  const [openIgnorePopup, setIgnorePopup] = useState(false);
  const [openAlertPopup, setAlertPopup] = useState(false);
  const [getIgnoreTruck, setIgnoreTruck] = useState({});
  const [unmatchedVehiclesData, setUnmatchedVehiclesData] = useState({});
  const [openAddTruckPopup, setOpenAddTruckPopup] = useState(false);
  const [addTruck, setAddTruck] = useState(schema.addTruckSchema);
  const [vehicleId, setVehicleId] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [state, dispatch] = useStore();
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const isFirstRender = useRef(true);

  // API calling to get list of staff user
  let getUnmatchedVehicles = () => {
    const params = {
      page: page + 1,
      size: rowsPerPage,
      ...(!!debounceSearch ? { search: debounceSearch } : {}),
      ...(!!order ? { order } : {}),
      ...(!!orderBy ? { orderBy } : {}),
    };
    dispatch({ type: FETCH_UNMATCHED_VEHICLES_LIST });
    API.get("unmatchedVehicles", { params })
      .then((response) => {
        dispatch({
          type: FETCH_UNMATCHED_VEHICLES_LIST_SUCCESS,
          payload: response.data.data,
        });
        setUnmatchedVehiclesData(response.data.data);
      })
      .catch((err) => {
        dispatch({ type: FETCH_UNMATCHED_VEHICLES_LIST_FAILURE, payload: err });
      });
  };

  /**
   * Add UnMatch Vehicle Dialog
   */
  // API calling to get list of truck types
  let getTruckType = () => {
    if (state.common.truckTypeData === null) {
      dispatch({ type: GET_TRUCK_TYPE });
      API.get("master/truckTypes")
        .then((response) => {
          dispatch({
            type: GET_TRUCK_TYPE_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((err) => {
          dispatch({ type: GET_TRUCK_TYPE_FAILURE, payload: err });
        });
    }
  };

  // API calling to get list of cities
  let getCities = () => {
    if (state?.common?.citiesData === null) {
      dispatch({ type: GET_CITIES });
      API.get("master/cities")
        .then((response) => {
          dispatch({
            type: GET_CITIES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CITIES_FAILURE, payload: error });
        });
    }
  };

  // API calling to add staff user
  const handleOpenAddTruckPopup = (truck) => {
    const data = {
      truckName: "",
      rego: truck.vehicles.registrationNumber,
      vehicleNumber: "",
      typeId: "",
      registrationDueDate: null,
      serviceDueDate: null,
      fleet: "",
      cityId: "",
    };

    // eslint-disable-next-line
    setVehicleId(truck.vehicleId);
    setAddTruck(data);
    setOpenAddTruckPopup(true);
    console.log(truck);
  }

  const handleCloseAddPopup = () => {
    setOpenAddTruckPopup(false);
    setAddTruck(schema.addTruckSchema);
    setError("");
    formik.handleReset();
  };

  const handleAddTruck = (value) => {
    const data = {
      truckName: value.truckName,
      rego: value.rego,
      vehicleNumber: value.vehicleNumber,
      typeId: value.typeId,
      registrationDueDate: moment(value.registrationDueDate).format(
        "YYYY-MM-DD"
      ),
      serviceDueDate: moment(value.serviceDueDate).format("YYYY-MM-DD"),
      fleet: value.fleet,
      cityId: value.cityId,
      vehicleId: vehicleId
    };
    dispatch({ type: ADD_UNMATCHED_VEHICLE });
    API.post("unmatchedVehicles/truck", data)
      .then((response) => {
        handleCloseAddPopup();
        toast.success("Truck Added Successfully");
        getUnmatchedVehicles();
        dispatch({
          type: ADD_UNMATCHED_VEHICLE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        setError(error.response.data.message);
        dispatch({ type: ADD_UNMATCHED_VEHICLE_FAILURE, payload: error });
      });
  }

  /**
   * Ignore Truck
   */
  // API calling to delete staff user
  const handleIgnore = () => {
    dispatch({ type: IGNORE_UNMATCHED_VEHICLE });
    API.post(`unmatchedVehicles/${getIgnoreTruck.vehicles.id}/ignoreUnmatchedVehicle`)
      .then((response) => {
        setIgnorePopup(false);
        getUnmatchedVehicles();
        dispatch({
          type: IGNORE_UNMATCHED_VEHICLE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        setAlertPopup(true);
        setIgnorePopup(false);
        setError(error?.response?.data?.message);
        dispatch({
          type: IGNORE_UNMATCHED_VEHICLE_FAILURE,
          payload: unmatchedVehiclesData,
        });
      });
  };

  const handleIgnorePopup = (truck) => {
    setIgnoreTruck(truck);
    setIgnorePopup(true);
    setError("");
  };

  const handleCloseAlertPopup = () => {
    setAlertPopup(false);
    getUnmatchedVehicles();
  }

  // Debounce the search after 1 second
  useDebouncedEffect(
    () => {
      setDebounceSearch(search);
    },
    1000,
    [search]
  );

  useEffect(() => {
    getUnmatchedVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(0);
    if (page === 0) {
      getUnmatchedVehicles();
    }
  }, [debounceSearch]);

  useEffect(() => {
    getTruckType();
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: addTruck,
    validationSchema: validationSchema.addTruckValidationSchema,
    onSubmit: (value) => {
      //API Add new Truck with selected Rego Number (RegistrationNumber)
      handleAddTruck(value);
    }
  });

  const handleSorting = (event, property) => {
    const isAsc = orderBy === property.sortTitle && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.sortTitle);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  return (
    <>
      <Header />
      <div className={classes.StaffListingWrapper}>
        <div className="setting-page wrapper">
          <div className="inner-page">
            <Typography variant="h1">Settings</Typography>
            <div className="setting-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar />
              </div>
              <div className="right-content">
                <div className="white-card right-content-inner">
                  <div className={classes.innerPageTopBlock}>
                    <div className="left-block">
                      <Typography variant="h1">Unmatched Vehicles</Typography>
                    </div>
                    <div className="right-block">
                      <div className="right-block-inner">
                        <div className="search-wrapper">
                          <div className="form-gourp">
                            <TextField
                              id="search-request"
                              placeholder="Search by Rego"
                              variant="outlined"
                              type="search"
                              className="search-field"
                              InputProps={{
                                endAdornment: <img src={SearchIcon} alt="Search"/>,
                              }}
                              value={search}
                              onChange={handleSearch}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TableListing
                    handleIgnoreTruck={(truck) => handleIgnorePopup(truck)}
                    handleAddTruck={(truck) => handleOpenAddTruckPopup(truck)}
                    handleSorting={(e, property) => handleSorting(e, property)}
                    orderBy={orderBy}
                    order={order}
                    page={page}
                    handleChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />

                  <AddTruckPopup
                    open={openAddTruckPopup}
                    handleClose={handleCloseAddPopup}
                    formik={formik}
                    error={error}
                  />

                  <IgnorePopup
                    open={openIgnorePopup}
                    handleClose={() => setIgnorePopup(false)}
                    handleConfirm={handleIgnore}
                  />

                  <AlertPopup
                    open={openAlertPopup}
                    handleClose={handleCloseAlertPopup}
                    error={error}
                    title={"Refresh Required"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StaffUser;
