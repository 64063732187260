import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { requestTypeEnum } from "@utils/constant";
import { AddPopup } from "./style";
import Loader from "@components/loader";

function Popup(props) {
  const classes = AddPopup();
  const [scroll] = useState("body");
  const [state] = useStore();
  const materilClasses = materialCommonStyles();
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customModal}
      scroll={scroll}
    >
      <div className="close-modal">
        <img src={closeIcon} alt="Close" onClick={props.handleClose} />
      </div>
      <form
        noValidate
        autoComplete="off"
        className={classes.customForm}
        onSubmit={props.formik.handleSubmit}
      >
        {props.error && <Alert severity="error">{props.error}</Alert>}
        <DialogTitle id="alert-dialog-title">
          {props.isEdit ? "Edit" : "Add"} Credential
        </DialogTitle>
        <DialogContent>
          <Loader loading={state?.apiCredential?.loadingCustomerCargowise} />
          <div className="form-row">
            <div className="form-gourp">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text" htmlFor="customer">
                  Customer
                </label>
                <Select
                  id="customer"
                  name="customer"
                  displayEmpty
                  className={materilClasses.customSelect}
                  placeholder="Select Customer"
                  MenuProps={{
                    classes: { paper: materilClasses.customSelect },
                  }}
                  onChange={props.formik.handleChange("customerId")}
                  value={props.formik.values.customerId}
                  IconComponent={() => <ExpandMore />}
                >
                  <MenuItem value={""} disabled>
                    Select Customer
                  </MenuItem>
                  {state?.apiCredential?.loadingCustomerCargowise ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : (
                    state?.apiCredential?.customerCargowiseData?.rows?.map(
                      (item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      }
                    )
                  )}
                </Select>
                <FormHelperText className="error-text">
                  {props.formik.touched.customerId && props.formik.errors.customerId}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="form-gourp">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text" htmlFor="url">
                  URL
                </label>
                <TextField
                  id="url"
                  name="url"
                  placeholder="Endpoint URL"
                  variant="outlined"
                  type="text"
                  onChange={props.formik.handleChange}
                  value={props.formik.values.url}
                  error={
                    props.formik.touched.url &&
                    Boolean(props.formik.errors.url)
                  }
                  helperText={
                    props.formik.touched.url && props.formik.errors.url
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="form-row">
            <div className="form-gourp">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text" htmlFor="apiCredential">
                  API Credential
                </label>
                <Select
                  id="credentialType"
                  name="credentialType"
                  displayEmpty
                  className={materilClasses.customSelect}
                  placeholder="Select Credential Type"
                  MenuProps={{
                    classes: { paper: materilClasses.customSelect },
                  }}
                  onChange={props.formik.handleChange("credentialTypeId")}
                  value={props.formik.values.credentialTypeId}
                  IconComponent={() => <ExpandMore />}
                >
                  <MenuItem value={""} disabled>
                    Select Credential Type
                  </MenuItem>
                  {state?.apiCredential?.loadingCredentialTypes ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : (
                      state?.apiCredential?.credentialTypesData?.map(
                      (item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      }
                    )
                  )}
                </Select>
                <FormHelperText className="error-text">
                  {props.formik.touched.credentialTypeId && props.formik.errors.credentialTypeId}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="form-gourp">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text" htmlFor="keyValue">
                  Value
                </label>
                {props.isEdit? (
                  <Tooltip title={props.formik.values.keyValue} arrow placement="top">
                    <TextField
                      id="keyValue"
                      name="keyValue"
                      placeholder="Key / Token value"
                      variant="outlined"
                      type="Text"
                      autoComplete="off"
                      onChange={props.formik.handleChange}
                      value={props.formik.values.keyValue}
                      error={
                        props.formik.touched.keyValue &&
                        Boolean(props.formik.errors.keyValue)
                      }
                      helperText={
                        props.formik.touched.keyValue &&
                        props.formik.errors.keyValue
                      }
                    />
                  </Tooltip>):(
                  <TextField
                    id="keyValue"
                    name="keyValue"
                    placeholder="Key / Token value"
                    variant="outlined"
                    type="Text"
                    autoComplete="off"
                    onChange={props.formik.handleChange}
                    value={props.formik.values.keyValue}
                    error={
                      props.formik.touched.keyValue &&
                      Boolean(props.formik.errors.keyValue)
                    }
                    helperText={
                      props.formik.touched.keyValue &&
                      props.formik.errors.keyValue
                    }
                  />
                )}
              </FormControl>
            </div>
          </div>

          <div className="form-row">
            <div className="form-gourp">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text" htmlFor="requestType">
                  Request Type
                </label>
                <Select
                  id="requestType"
                  name="requestType"
                  displayEmpty
                  className={materilClasses.customSelect}
                  MenuProps={{
                    classes: { paper: materilClasses.customSelect },
                  }}
                  onChange={props.formik.handleChange("requestType")}
                  value={props.formik.values.requestType}
                  IconComponent={() => <ExpandMore />}
                >
                  <MenuItem value={""} disabled>
                    Select Request Type
                  </MenuItem>
                  {
                    requestTypeEnum.map(
                      (item, index) => {
                        return (
                          <MenuItem key={index} value={item.key}>
                            {item.value}
                          </MenuItem>
                        );
                      }
                    )
                  }
                </Select>
                <FormHelperText className="error-text">
                  {props.formik.touched.requestType && props.formik.errors.requestType}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="bottom-button-block">
          <Button
            className="primary-btn gray-border-btn"
            color="inherit"
            disableElevation
            underlinenone="true"
            onClick={props.handleClose}
            type="reset"
          >
            CANCEL
          </Button>
          <Button
            className="orange-btn primary-btn"
            color="inherit"
            disableElevation
            underlinenone="true"
            type="submit"
            disabled={state?.apiCredential?.addingAPiCredential || state?.apiCredential?.editingApiCredential}
          >
            {state?.apiCredential.addingAPiCredential || state?.apiCredential?.editingApiCredential ? (
              <CircularProgress color="inherit" />
            ) : props.isEdit ? (
              "Edit Credential"
            ) : (
              "Add Credential"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Popup;
