import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";

import { useStore } from "@store/store";
import Loader from "@components/loader";
import Pagination from "@components/pagination";
import { TableStyle } from "./style";
import { apiCredentialHeading } from "@utils/constant";
import { utcToTimezone } from "@utils/commonFunctions";
import { requestTypeEnum } from "@utils/constant";

function TableListing(props) {
  const [state] = useStore();
  const classes = TableStyle();

  const getRequestTypeName = (id) => {
    return requestTypeEnum?.filter(item => item.key === id)[0]?.value;
  }

  return (
    <>
      <div className={classes.TableWrapper}>
        <Loader loading={state?.apiCredential?.loading} />
        <TableContainer component={Paper} className={classes.customTable}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {apiCredentialHeading.map((item, index) => {
                  return (
                    <TableCell key={index} className={item.className}>
                      {item.title}
                      {item.sort && (
                        <TableSortLabel
                          direction={
                            props.orderBy === item.sortTitle
                              ? props.order
                              : "desc"
                          }
                          active={true}
                          onClick={(e) => props.handleSorting(e, item)}
                        ></TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.apiCredential?.apiCredentialData?.count === 0 ? (
                <TableRow>
                  <TableCell colspan="6">No Data Found</TableCell>
                </TableRow>
              ) : (
                state?.apiCredential?.apiCredentialData?.rows?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="edit-link">
                      <span onClick={() => props.handleEdit(item)}>Edit</span>
                    </TableCell>
                    <TableCell className="delete-link">
                      <span onClick={() => props.handleOpen(item)}>Delete</span>
                    </TableCell>
                    <TableCell className="customerName">{item?.customers.name}</TableCell>
                    <TableCell className="requestType">{item?.requestType ? getRequestTypeName(item?.requestType): "-"}</TableCell>
                    <TableCell className="url">{item.url}</TableCell>
                    <TableCell className="keyValue">{item.keyValue}</TableCell>
                    <TableCell className="dateCreated">
                      { item.createdAt
                      ? utcToTimezone(
                        item.createdAt,
                        item.cities?.timezone,
                        "DD/MM/YYYY"
                      )
                      : ""}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {state?.apiCredential?.apiCredentialData?.count !== 0 && (
          <div className="pagination-wrapper">
            <Pagination
              count={state?.apiCredential?.apiCredentialData?.count}
              page={props.page}
              handleChangePage={props.handleChangePage}
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default TableListing;
