import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { AlertPopupStyle } from "./style";

function DeletePopup(props) {
  const classes = AlertPopupStyle();
  const [scroll] = useState("body");
  return (
    <div className={classes.DeleteWrapper}>
      <Dialog
        open={props.open}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.customModal}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color="primary"
            className="primary-btn orange-btn"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default DeletePopup;
