import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  FormControl
} from "@material-ui/core";
import { useFormik } from "formik";
import Loader from "@components/loader";

import Header from "@components/header";
import ProfileSideBar from "@components/profile-sidebar";
import {FoundUIntegrationPageStyle} from "./style";
import { useStore } from "@store/store";
import { toast } from "react-toastify";
import API from "@services/axios";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";

import {
  SEND_AUTHEN_TOKEN,
  SEND_AUTHEN_TOKEN_SUCCESS,
  SEND_AUTHEN_TOKEN_FAILURE,
  FORCE_REFRESH_TOKEN,
  FORCE_REFRESH_TOKEN_SUCCESS,
  FORCE_REFRESH_TOKEN_FAILURE,
  FETCH_LAST_REFRESH_TOKEN,
  FETCH_LAST_REFRESH_TOKEN_SUCCESS,
  FETCH_LAST_REFRESH_TOKEN_FAILURE,
} from "@utils/actionTypes";
import foundULogo from "@assets/images/foundU-logo.svg";
import { utcToLocalTime } from "@utils/commonFunctions";

function FoundUIntegration() {
  const classes = FoundUIntegrationPageStyle();
  const [authenticatedToken, setAuthenticatedToken] = useState(schema.foundUIntegrationTokenSchema);
  const [lastRefreshAt, setLastRefreshAt] = useState("");
  const [status, setstatus] = useState("");
  const [authenticationToken, setauthenticationToken] = useState("");
  const [state, dispatch] = useStore();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getLastRefreshToken = () => {
    dispatch({type: FETCH_LAST_REFRESH_TOKEN});
    API.get("foundUIntegration/lastRefreshToken")
      .then((response) => {
        setLastRefreshAt(
          response.data.data.lastRefreshToken
            ? utcToLocalTime(response.data.data.lastRefreshToken, userTimezone)
            : "Initiate integration has been setup"
        );
        setstatus(
          response.data.data.status
        )
        setauthenticationToken(
          response.data.data.authToken
        )
        dispatch({type: FETCH_LAST_REFRESH_TOKEN_SUCCESS, payload: response.data});
      })
      .catch((error) => {
        dispatch({type: FETCH_LAST_REFRESH_TOKEN_FAILURE, payload: error.response.data});
      });
  };

  useEffect(() => {
    getLastRefreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API calling to send Authenticated Token
  let sendAuthToken = (token) => {
    dispatch({ type: SEND_AUTHEN_TOKEN });
    API.post("foundUIntegration/initiateToken", {
      authenticationToken: token
    }).then((response) => {
      toast.success("Send Authenticated Token Successfully");
      dispatch({
        type: SEND_AUTHEN_TOKEN_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      dispatch({ type: SEND_AUTHEN_TOKEN_FAILURE, payload: error });
    });
  };

  // API calling to force refresh token
  let forceRefreshToken = () => {
    dispatch({ type: FORCE_REFRESH_TOKEN });
    API.post("foundUIntegration/refreshToken").then((response) => {
      toast.success("Refresh Token Successfully");
      dispatch({
        type: FORCE_REFRESH_TOKEN_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      dispatch({ type: FORCE_REFRESH_TOKEN_FAILURE, payload: error });
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: authenticatedToken,
    validationSchema: validationSchema.foundUIntegrationTokenSchema,
    onSubmit: (values) => {
      // Call Api Send Authen Token
      sendAuthToken(values.tokenValue);
    },
  });

  return (
    <>
      <Header/>
      <div className={classes.FoundUIntegrationWrapper}>
        <Loader loading={state?.foundUIntegration.loading} />
        <div className="setting-page wrapper">
          <div className="inner-page">
            <Typography variant="h1">Settings</Typography>
            <div className="setting-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar/>
              </div>
              <div className="right-content">
                <div className="white-card right-content-inner">
                  <form
                    noValidate
                    autoComplete="off"
                    className="custom-form"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="form-outer">
                      <img src={foundULogo} alt="foundULogo" />
                      <Typography variant="h2"></Typography>
                      <div className="form-row">
                        <div className="form-group three-column authen-token-field">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <div className="label-wrapper">
                              <label className="label-text">Initiate Integration</label>
                            </div>
                            <TextField
                              id="tokenValue"
                              name="tokenValue"
                              placeholder="Authen token"
                              variant="outlined"
                              onChange={formik.handleChange("tokenValue")}
                              value={formik.values.tokenValue}
                              error={
                                formik.touched.tokenValue &&
                                Boolean(formik.errors.tokenValue)
                              }
                              helperText={
                                formik.touched.tokenValue && formik.errors.tokenValue
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="form-group button-outer">
                          <Button
                            className="orange-btn lg primary-btn"
                            color="inherit"
                            disableElevation
                            type="submit"
                          >
                            Send
                          </Button>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group authen-token-field">
                          <FormControl variant="outlined" className={`${classes.formControl} form-outlined`}>
                            <div className="label-wrapper">
                              <label className="label-text">Refresh Token</label>
                              <ul>
                                <li className="label-wrapper title">
                                  <label className="label-text">- Last refresh at:</label>{lastRefreshAt}
                                </li>
                                <li className="label-wrapper title">
                                  <label className="label-text">- Status:</label>{status}
                                </li>
                                <li className="label-wrapper title">
                                  <label className="label-text">- Initial authen token:</label>{authenticationToken}
                                </li>
                              </ul>
                            </div>
                            <div className="refresh-button">
                              <Button
                                className="orange-btn lg primary-btn"
                                color="inherit"
                                disableElevation
                                type="button"
                                onClick={() => forceRefreshToken()}
                              >
                                Force Refresh Token
                              </Button>
                            </div>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="foundU-img-holder"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FoundUIntegration;
