import { sourceId } from "./constant";

const basic = {
	basic: "",
};
export { basic };

const schema = {
	loginSchema: {
		email: "",
		password: "",
	},

	createJobSchema: {
		requesterName: "",
		cityId: "",
		customerId: "",
		jobTypeId: "",
		cargoTypeId: "",
		driverId: "",
		pickUpLocation: "",
		pickUpLatitude: "",
		pickUpLongitude: "",
		pickUpCustomerAddressId: "",
		dropOffLocation: "",
		dropOffLatitude: "",
		dropOffLongitude: "",
		dropOffCustomerAddressId: "",
		description: "",
		ctoId: "",
		jobTypeLabel: "",
		weight: 0,
		quantity: 0,
		ctoData: {},
		radioType: "",
		isPreloadChargeApplied: false,
		cityName: "",
		jobTypeName: "",
		customerName: "",
		cargoTypeName: "",
		ctoName: "",
	},

	createCompetedJobSchema: {
		requesterName: "",
		cityId: "",
		customerId: "",
		jobTypeId: "",
		cargoTypeId: "",
		driverId: "",
		pickUpLocation: "",
		pickUpLatitude: "",
		pickUpLongitude: "",
		pickUpCustomerAddressId: "",
		dropOffLocation: "",
		dropOffLatitude: "",
		dropOffLongitude: "",
		dropOffCustomerAddressId: "",
		description: "",
		ctoId: "",
		jobTypeLabel: "",
		weight: 0,
		quantity: 0,
		ctoData: {},
		radioType: "",
		truckId: "",
		trailerId: "",
		startedAt: null,
		completedAt: null,
		isPreloadChargeApplied: false
	},

	addAirWayBillSchema: {
		number: "",
		flight: "",
		weight: "",
		quantity: "",
		weightLoaded: "",
		quantityLoaded: "",
		remainderQty: "",
		remainderReason: "",
		readyDate: null,
		readyTime: null,
		cutOffTime: null,
		airWaybillStatusId: "",
		id: "",
	},

	addConsignMentSchema: {
		number: "",
		photo: "",
		weight: "",
		quantity: "",
		id: "",
		weightLoaded: "",
		quantityLoaded: "",
		remainderQty: "",
		remainderReason: "",
	},

	addConsignMentItemSchema: {
		additionalInfo: "",
		quantity: "",
		photo: "",
		id: "",
		viewPhoto: "",
	},

	addCustomerSchema: {
		oldCustomerNumber: "",
		name: "",
		parentId: "",
		address: "",
		email: "",
		phone: "",
		status: 1,
		invoicingPeriodId: "",
		logo: "",
		city: "",
		jobTypeId: [],
		latitude: "",
		longitude: "",
		customerCategoryId: "",
		isParent: false,
		paymentTerms: "",
		ABN: "",
		postalCode: "",
		isCargoWise: false,
	},

	addULDSchema: {
		volume: "",
		quantity: 1,
		uldNumber: "",
		overhang: false,
		id: "",
		number: "",
	},

	addLooseSchema: {
		quantity: "",
		id: "",
	},

	issueInvoiceSchema: {
		issueDate: null,
		datePayable: null,
		division: [],
	},

	resetPasswordSchema: {
		password: "",
		confirmPassword: "",
	},

	forgotPasswordSchema: {
		email: "",
	},
	addDriverSchema: {
		name: "",
		phone: "",
		password: "",
		confirmPassword: "",
		email: "",
		cityId: "",
		driverTypeId: "",
		licenseTypeId: "",
		asicTypeId: "",
		active: 1,
		weekdayWage: "",
		saturdayWage: "",
		sundayWage: "",
		publicHoliday1Wage: "",
		publicHoliday2Wage: "",
		employeeNumber: "",
	},

	assignDriverSchema: {
		assignDriver: "",
	},

	addCustomerUserSchema: {
		name: "",
		email: "",
		phone: "",
		password: "",
		confirmPassword: "",
		isMapCockpitAutoRefresh: "",
	},
	addTrailerSchema: {
		name: "",
		rego: "",
		serviceDate: null,
		registrationDate: null,
		fleet: "",
		cityId: "",
	},

	addTruckSchema: {
		truckName: "",
		vehicleNumber: "",
		rego: "",
		typeId: "",
		serviceDueDate: null,
		registrationDueDate: null,
		cityId: "",
		fleet: "",
	},

	makeRequestSchema: {
		jobType: "",
		requesterName: "",
		email: "",
		cc: [],
		pickupLocation: "",
		dropOffLocation: "",
		city: "",
	},

	addTollSchema: {
		latitude: null,
		longitude: null,
		cost: null,
		address: "",
	},
	addCTOSchema: {
		name: "",
		logo: "",
		latitude: null,
		longitude: null,
		location: "",
		cityId: "",
	},
	addHolidaysSchema: {
		date: null,
		name: "",
		type: "",
		city: "",
	},

	addStaffUserSchema: {
		name: "",
		email: "",
		status: 1,
		roleId: "",
		password: "",
		confirmPassword: "",
	},

	addConnectedCustomerSchema: {
		name: "",
		email: "",
		phone: "",
		password: "",
		confirmPassword: "",
		parentCustomer: [],
	},

	addRoleSchema: {
		name: "",
		status: 1,
	},

	updateProfileSchema: {
		name: "",
		email: "",
		currentPassword: "",
		newPassword: "",
	},
	addCustomerAddressSchema: {
		address: "",
		latitude: "",
		longitude: "",
	},

	minimumContractSchema: {
		uldValue: "",
		uldAmount: "",
		looseValue: "",
		looseAmount: "",
	},

	priceMatrixConsignmentSchema: {
		weekDaysWage: 0,
		weekDaysChargeType: "",
		saturdayWage: 0,
		saturdayChargeType: "",
		sundayWage: 0,
		sundayChargeType: "",
		publicHoliday1Wage: 0,
		publicHoliday1ChargeType: "",
		publicHoliday2Wage: 0,
		publicHoliday2ChargeType: "",
	},
	addCreditNoteSchema: {
		id: "",
		childCustomer: "",
		date: new Date(),
		desc: "",
		amt: "",
		photo: "",
		organisation: "",
		viewPhoto: "",
	},

	addDocumentSchema: {
		name: "",
		document: "",
	},

	dailyMinimumHoursRigid: {
		truckTypeId: "",
		weekdayHours: 0,
		weekdayCharge: 0,
		saturdayHours: 0,
		saturdayCharge: 0,
		sundayHours: 0,
		sundayCharge: 0,
		publicHoliday1Hours: 0,
		publicHoliday1Charge: 0,
		publicHoliday2Hours: 0,
		publicHoliday2Charge: 0,
	},

	dailyMinimumHoursRigidConsignment: {
		truckTypeId: "",
		weekdayHours: 0,
		weekdayCharge: 0,
		saturdayHours: 0,
		saturdayCharge: 0,
		sundayHours: 0,
		sundayCharge: 0,
		publicHoliday1Hours: 0,
		publicHoliday1Charge: 0,
		publicHoliday2Hours: 0,
		publicHoliday2Charge: 0,
	},

	waitingTimeSchema: {
		id: 1,
		isEnable: true,
		freeWaitingTime: 0,
		waitingTimeInterval: 0,
		weekdayCharge: 0,
		saturdayCharge: 0,
		sundayCharge: 100,
		publicHoliday1Charge: 0,
		publicHoliday2Charge: 0,
	},

	addOrganisationSchema: {
		id: "",
		name: "",
		parentOrganisationId: "",
		cityId: "",
		address1: "",
		address2: "",
		suburb: "",
		postalCode: "",
		countryId: "",
		stateId: "",
		ABN: "",
		phone: "",
		fax: "",
		email: "",
		BSB: "",
		accountNumber: "",
		bank: "",
		accountName: "",
		photo: "",
		viewPhoto: "",
	},

	addParentOrganisationSchema: {
		id: "",
		name: "",
	},

	jobsToll: {
		name: "",
		charge: "",
	},

	sftpSchema: {
		host: "",
		port: "",
		userName: "",
		password: "",
		isReadEnable: "",
		readPath: "",
		isUploadEnable: "",
		finishLoadingPath: "",
		finishUnloadingPath: "",
		runsheetPath: "",
	},

	generateTokenSchema: {
		tokenName: "",
		job: 1,
		truck: 1,
	},

  foundUIntegrationTokenSchema: {
    tokenValue: ""
  },

  addGeofenceSchema: {
    name: "",
    city: "",
    category: "",
    geofenceId: []
  },

  addApiIntegrationSchema: {
    customerId: "",
    url: "",
    credentialTypeId: "",
    keyValue: "",
    requestType: ""
  },
};

export { schema };
