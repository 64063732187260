import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { toPng } from "html-to-image";

import closeIcon from "@assets/images/close.svg";
import { CreateQrCodeStyle } from "./style";

function QRCodePopUp(props) {
  const { dataQrCode, handleClose }= props;
  const classes = CreateQrCodeStyle();
  const [scroll] = useState("body");
  const captureRef = useRef();

  const handleCapture = () => {
    if (captureRef.current) {
      toPng(captureRef.current)
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `Job Assignment_QR Code_${dataQrCode.cityName}_${(new Date().toJSON().slice(0,10))}.png`;
            link.click();
            handleClose(true);
          })
          .catch((err) => {
            console.error("Error capturing image:", err);
          });
    }
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customModal}
      scroll={scroll}
    >
      <div className="close-modal">
        <img src={closeIcon} alt="Close" onClick={() => handleClose()} />
      </div>
      <DialogTitle id="alert-dialog-title">
        Job Assignment
      </DialogTitle>
      <form
        noValidate
        autoComplete="off"
        className={classes.customForm}
      >
        <DialogContent>
          <div
            ref={captureRef}
            className="capture-qr">
            <div className="group-info-job">
              <div>
                <span>City: </span>
                <b>{dataQrCode.cityName}</b>
                {' - '}
                <span>Job Type: </span>
                <b>{dataQrCode.jobTypeName}</b>
              </div>

              {props.qrCodeUrl && <img src={props.qrCodeUrl} alt="Generated QR Code" /> }

              <div className="info-detail">
                <span>Customer: <b>{dataQrCode.customerName}</b></span>
                {dataQrCode.cargoTypeName && (<span className="tab-size">Cargo Type: <b>{dataQrCode.cargoTypeName}</b></span>)}
                {dataQrCode.ctoName && (<span className="tab-size">CTO: <b>{dataQrCode.ctoName}</b></span>)}
                {dataQrCode.pickUpCustomerAddressName && (<span className="tab-size">Pick up: <b>{dataQrCode.pickUpCustomerAddressName}</b></span>)}
                {dataQrCode.dropOffCustomerAddressName && (<span className="tab-size">Drop off: <b>{dataQrCode.dropOffCustomerAddressName}</b></span>)}
                {dataQrCode.quantity && (<span className="tab-size">Quantity: <b>{dataQrCode.quantity}</b></span>)}
                {dataQrCode.description && (<span className="tab-size">Desc: <b>{dataQrCode.description}</b></span>)}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="bottom-button-block">
          <Button
            className="orange-btn primary-btn"
            color="inherit"
            disableElevation
            onClick={handleCapture}
          >
            Export
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default QRCodePopUp;
