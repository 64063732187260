import { makeStyles } from "@material-ui/core/styles";

const AddGeoFenceStyle = makeStyles({
  importantMargin: {
    marginRight: "0 !important",
    marginLeft: "0 !important",
  }
});

export { AddGeoFenceStyle };
