import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@utils/constant";

const CreateQrCodeStyle = makeStyles({
    customModal: {
        "& .MuiDialog-paperWidthSm": {
            boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.24)",
            padding: "36px 40px 40px",
            maxWidth: "600px",
            width: "100%",
            "@media (max-width: 767px)": {
                maxWidth: "520px",
            },
            "@media (max-width: 600px)": {
                maxWidth: "480px",
            },
            "& .MuiDialogTitle-root": {
                padding: "0",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "30px",
                "@media (max-width: 480px)": {
                    marginBottom: "20px",
                },
            },
            "& .MuiDialogContent-root": {
                width: "100%",
                padding: "0",
                overflow: "hidden",
            },
            "& h2": {
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "40px",
            },
        },
        "& .close-modal": {
            position: "absolute",
            right: "40px",
            top: "45px",
            cursor: "pointer",
        },
        "& .MuiFormControl-root": {
            "& > div": {
                "&::after": {
                    display: "none",
                },
            },
        },
        "& .MuiInputBase-root.MuiInput-root": {
            border: "none",
            "& input": {
                border: "none",
            },
        },
        "& .MuiFormControl-marginNormal": {
            margin: "0",
        },
        "& .bottom-button-block": {
            padding: "15px 0 0",
            display: "flex",
            justifyContent: "space-between",
            "& button": {
                height: "50px",
                lineHeight: "50px",
                fontWeight: "600",
                fontSize: "16px",
                minWidth: "165px",
                "@media (max-width: 480px)": {
                    width: "100%",
                    marginBottom: "15px",
                },
            },
        },
    },
    customForm: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .capture-qr": {
            padding: "15px",
            backgroundColor: "#ffffff",
            border: "1px solid #ffffff",
            display: "inline-block",
            "& .group-info-job": {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                border: "1px solid black",
                justifyContent: "center",
                "& .info-detail": {
                    textAlign: "center",
                    "& .tab-size": {
                        marginLeft: "15px"
                    }
                },
                "& img": {
                    margin: "20px",
                    "@media (max-width: 480px)": {
                        maxWidth: "300px"
                    },
                },
            }
        },

    },
});

export { CreateQrCodeStyle };
