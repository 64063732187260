import {
  FETCH_UNMATCHED_VEHICLES_LIST,
  FETCH_UNMATCHED_VEHICLES_LIST_SUCCESS,
  FETCH_UNMATCHED_VEHICLES_LIST_FAILURE,
  ADD_UNMATCHED_VEHICLE,
  ADD_UNMATCHED_VEHICLE_SUCCESS,
  ADD_UNMATCHED_VEHICLE_FAILURE,
  IGNORE_UNMATCHED_VEHICLE,
  IGNORE_UNMATCHED_VEHICLE_SUCCESS,
  IGNORE_UNMATCHED_VEHICLE_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loading: false,
  unmatchedVehicleListData: null,
  addingUnmatchedVehicle: false,
  addUnmatchedVehicleData: null,
  ignoringUnmatchedVehicle: false,
  ignoreUnmatchedVehicleData: null,
};

const unmatchedVehicleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_VEHICLES_LIST:
      return { ...state, loading: true };
    case FETCH_UNMATCHED_VEHICLES_LIST_SUCCESS:
      return { ...state, unmatchedVehicleListData: action.payload, loading: false };
    case FETCH_UNMATCHED_VEHICLES_LIST_FAILURE:
      return { ...state, unmatchedVehicleListData: action.payload, loading: false };

    case ADD_UNMATCHED_VEHICLE:
      return { ...state, addingUnmatchedVehicle: true };
    case ADD_UNMATCHED_VEHICLE_SUCCESS:
      return { ...state, addUnmatchedVehicleData: action.payload, addingUnmatchedVehicle: false };
    case ADD_UNMATCHED_VEHICLE_FAILURE:
      return { ...state, addUnmatchedVehicleData: action.payload, addingUnmatchedVehicle: false };

    case IGNORE_UNMATCHED_VEHICLE:
      return { ...state, ignoringUnmatchedVehicle: true };
    case IGNORE_UNMATCHED_VEHICLE_SUCCESS:
      return { ...state, ignoreUnmatchedVehicleData: action.payload, ignoringUnmatchedVehicle: false };
    case IGNORE_UNMATCHED_VEHICLE_FAILURE:
      return { ...state, ignoreUnmatchedVehicleData: action.payload, ignoringUnmatchedVehicle: false };

    default:
      return state;
  }
};
export default unmatchedVehicleReducer;
