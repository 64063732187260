import {
  FORCE_REFRESH_TOKEN,
  FORCE_REFRESH_TOKEN_FAILURE,
  FORCE_REFRESH_TOKEN_SUCCESS,
  FETCH_LAST_REFRESH_TOKEN,
  FETCH_LAST_REFRESH_TOKEN_FAILURE,
  FETCH_LAST_REFRESH_TOKEN_SUCCESS,
  SEND_AUTHEN_TOKEN,
  SEND_AUTHEN_TOKEN_FAILURE,
  SEND_AUTHEN_TOKEN_SUCCESS,
} from "@utils/actionTypes";

const INIT_STATE = {
  loading: false,
  data: null,
  lastTokenRefresh: null,
};

const foundUIntegrationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_AUTHEN_TOKEN:
      return { ...state, loading: true };
    case SEND_AUTHEN_TOKEN_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case SEND_AUTHEN_TOKEN_FAILURE:
      return { ...state, data: action.payload, loading: false };
    case FORCE_REFRESH_TOKEN:
      return { ...state, loading: true };
    case FORCE_REFRESH_TOKEN_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case FORCE_REFRESH_TOKEN_FAILURE:
      return { ...state, data: action.payload, loading: false };
    case FETCH_LAST_REFRESH_TOKEN:
      return { ...state, loading: true };
    case FETCH_LAST_REFRESH_TOKEN_SUCCESS:
      return { ...state, lastTokenRefresh: action.payload, loading: false };
    case FETCH_LAST_REFRESH_TOKEN_FAILURE:
      return { ...state, lastTokenRefresh: action.payload, loading: false };
    default:
      return state;
  }
};
export default foundUIntegrationReducer;
