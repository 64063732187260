import React from "react";
import { Button } from "@material-ui/core";

import AddGeoFencePopup from "../add-geofence-popup";
import { AddGeoFenceStyle } from "./style";
import { getPermissions, isRolePermission } from "@utils/commonFunctions";

function AddGeoFences(props) {
  const classes = AddGeoFenceStyle();

  return (
    <>
      {getPermissions() && isRolePermission(getPermissions(), "geofences") && (
        <Button
          className={`orange-btn primary-btn ${classes.importantMargin}`}
          color="inherit"
          disableElevation
          onClick={props.handleClickOpen}
        >
          ADD GEOFENCE
        </Button>
      )}

        <AddGeoFencePopup
          open={props.open}
          handleClose={props.handleClose}
          formik={props.formik}
          isEdit={props.isEdit}
          error={props.error}
        />
    </>
  );
}

export default AddGeoFences;
