import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";

import { useStore } from "@store/store";
import Loader from "@components/loader";
import Pagination from "@components/pagination";
import { TableStyle } from "./style";
import { unmatchedVehiclesHeading } from "@utils/constant";
import { convertMysqlDateToYYYYMMDD } from "@utils/commonFunctions";
import { getPermissions, isRolePermission } from "@utils/commonFunctions";

function TableListing(props) {
  const [state] = useStore();
  const classes = TableStyle();

  return (
    <>
      <div className={classes.TableWrapper}>
        <Loader loading={state?.unmatchedVehicle?.loading} />
        <TableContainer component={Paper} className={classes.customTable}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {unmatchedVehiclesHeading?.map((item, index) => {
                  return (
                    <TableCell className={item.className} key={index}>
                      <div className={`flex-box`}>
                        <span className={item.className}>{item.title}</span>
                          {item.sort && (
                          <TableSortLabel
                            direction={
                              props.orderBy
                                ? props.orderBy === item.sortTitle
                                  ? props.order
                                  : "desc"
                                : item.sortTitle === "city"
                                  ? "asc"
                                  : "desc"
                            }
                            active={true}
                            onClick={(e) => props.handleSorting(e, item)}
                          />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.unmatchedVehicle?.unmatchedVehicleListData?.count === 0 ? (
                <TableRow>
                  <TableCell colspan="8">No Data Found</TableCell>
                </TableRow>
              ) : (
                state?.unmatchedVehicle?.unmatchedVehicleListData?.rows?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="name">{item.vehicles.name}</TableCell>
                    <TableCell className="vehicleNumber">{item.vehicles.number}</TableCell>
                    <TableCell className="registrationNumber">{item.vehicles.registrationNumber}</TableCell>
                    <TableCell className="vin">{item.vehicles.vin}</TableCell>
                    <TableCell className="makeModel">{item.vehicles.make + " " + item.vehicles.model}</TableCell>
                    <TableCell className="year">{item.vehicles.year}</TableCell>
                    <TableCell className="lastSyncDate">
                      {item.updatedAt
                        ? convertMysqlDateToYYYYMMDD(item.updatedAt)
                        : "-"}
                    </TableCell>
                    <TableCell className="action">
                      {getPermissions() && isRolePermission(getPermissions(), "truck") && (
                        <span className={"edit-link"} onClick={() => props.handleAddTruck(item)}>Create Truck &nbsp;&nbsp;&nbsp;&nbsp;</span>

                      )}
                      <span className={"delete-link"} onClick={() => props.handleIgnoreTruck(item)}>Ignore</span>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {state?.unmatchedVehicle?.unmatchedVehicleListData?.count !== 0 && (
          <div className="pagination-wrapper">
            <Pagination
              count={state?.unmatchedVehicle?.unmatchedVehicleListData?.count}
              page={props.page}
              handleChangePage={props.handleChangePage}
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default TableListing;
