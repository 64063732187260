import { makeStyles } from "@material-ui/core";
import { colors } from "@utils/constant";
import foundUPermissionImg from "@assets/images/foundU-permission.png";

const FoundUIntegrationPageStyle = makeStyles((theme) => ({
  FoundUIntegrationWrapper: {
    "& .MuiBackdrop-root": {
      color: " #fff",
      zIndex: "1201",
    },
    "& .setting-page": {
      "& h1": {
        color: colors.darkBlack,
        textTransform: "none",
      },
      "& .right-content": {
        "& .right-content-inner": {
          padding: "30px",
          "@media (max-width: 767px)": {
            padding: "20px",
          },
        },
      },
      "& .form-outer": {
        "& .form-row": {
          display: "flex",
          margin: "0 -15px",
          "@media (max-width: 991px)": {
            flexWrap: "wrap",
          },
          "& .form-group": {
            marginBottom: "25px",
          },
          "& .three-column": {
            flex: "0 0 33%",
            maxWidth: "50%",
            padding: "0 15px",
            "@media (max-width: 991px)": {
              flex: "0 0 100%",
              maxWidth: "100%",
            },
            "& .right-content": {
              "& .right-content-inner": {
                padding: "30px",
                "@media (max-width: 767px)": {
                  padding: "20px",
                },
              },
            },
            "& .form-outer": {
              "& + .form-outer": {
                marginTop: "10px",
              },
              "& .form-row": {
                display: "flex",
                margin: "0 -15px",
                "@media (max-width: 991px)": {
                  flexWrap: "wrap",
                },
                "& .form-group": {
                  marginBottom: "25px",
                },
                "& .two-column": {
                  flex: "0 0 50%",
                  maxWidth: "50%",
                  padding: "0 15px",
                  "@media (max-width: 991px)": {
                    flex: "0 0 100%",
                    maxWidth: "100%",
                  },
                },
              },
            },
          },
          "& .authen-token-field": {
            "& li": {
              paddingLeft: "10px"
            },
            "& .form-outlined":{
              paddingLeft: "15px"
            },
            "& .Mui-error#tokenValue-helper-text": {
              position: "absolute",
              top: "48px"
            },
            "& .title": {
                display: "flex",
                "& label": {
                  marginRight: "10px"
                }
            }
          },
          "& .button-outer":{
            display: "flex",
            alignItems: "flex-end",
            marginRight: "15px",
            "@media (max-width: 991px)": {
              marginLeft: "15px",
              "&:last-child": {
                marginLeft: "0"
              }
            },
            "@media (max-width: 870px)": {
              width: "100%",
              "& button": {
                width: "100%"
              },
              "&:last-child": {
                marginLeft: "15px"
              }
            }
          },
        },
      },
    },
    "& .foundU-img-holder": {
      backgroundImage: "url(" + foundUPermissionImg + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      height: "520px",
      maxWidth: "700px",
      marginTop: "10px"
    },

    "& .refresh-button":{
      marginTop: "10px"
    }
  },
}));

export { FoundUIntegrationPageStyle };
