import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {TextField, Typography} from "@material-ui/core";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import Header from "@components/header";
import ProfileSideBar from "@components/profile-sidebar";
import CustomDeletePopup from "@components/customDeletePopup";
import {
  FETCH_API_CREDENTIAL,
  FETCH_API_CREDENTIAL_SUCCESS,
  FETCH_API_CREDENTIAL_FAILURE,
  ADD_API_CREDENTIAL,
  ADD_API_CREDENTIAL_SUCCESS,
  ADD_API_CREDENTIAL_FAILURE,
  EDIT_API_CREDENTIAL,
  EDIT_API_CREDENTIAL_SUCCESS,
  EDIT_API_CREDENTIAL_FAILURE,
  DELETE_API_CREDENTIAL,
  DELETE_API_CREDENTIAL_SUCCESS,
  DELETE_API_CREDENTIAL_FAILURE,
  FETCH_CUSTOMER_WITH_CARGOWISE,
  FETCH_CUSTOMER_WITH_CARGOWISE_SUCCESS,
  FETCH_CUSTOMER_WITH_CARGOWISE_FAILURE,
  GET_CREDENTIAL_TYPES,
  GET_CREDENTIAL_TYPES_SUCCESS,
  GET_CREDENTIAL_TYPES_FAILURE
} from "@utils/actionTypes";
import { schema } from "@utils/schemas";
import { rowsPerPageVal, credentialEnum } from "@utils/constant";
import validationSchema from "@utils/validationSchemas";
import API from "@services/axios";
import AddEditApiCredential from "./add-api-credential";
import TableListing from "./table-listing";
import { ApiCredentialStyle } from "./style";
import SearchIcon from "@assets/images/search.svg";
import { useDebouncedEffect } from "@hooks/debounceEffect";

function ApiCredential() {
  const classes = ApiCredentialStyle();
  const [openDeletePopup, setDeletePopup] = useState(false);
  const [getDeleteApiCredential, setDeleteApiCredential] = useState({});
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [getEditApiCredential, setEditApiCredential] = useState(schema.addApiIntegrationSchema);
  const [getEdit, setEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [state, dispatch] = useStore();
  const [err, setError] = useState("");
  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const isFirstRender = useRef(true);

  // API calling to get list of Api Credential
  let getApiCredentials = () => {
    const params = {
      page: page + 1,
      size: rowsPerPage,
      ...(!!debounceSearch ? { search: debounceSearch } : {}),
      ...(!!order ? { order } : {}),
      ...(!!orderBy ? { orderBy } : {}),
    };
    dispatch({ type: FETCH_API_CREDENTIAL });
    API.get("cargoWiseIntegration/credentials", { params })
      .then((response) => {
        dispatch({
          type: FETCH_API_CREDENTIAL_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_API_CREDENTIAL_FAILURE, payload: err });
      });
  };

  // API calling to add Api Credential
  let addApiCredential = (value) => {
    const data = {
      customerId: value.customerId,
      url: value.url,
      credentialTypeId: value.credentialTypeId,
      keyValue: value.keyValue,
      requestType: value.requestType
    };
    dispatch({ type: ADD_API_CREDENTIAL });
    API.post("cargoWiseIntegration/credentials", data)
      .then((response) => {
        handleCloseAddPopup();
        getApiCredentials();
        toast.success("API Credential Added Successfully");
        dispatch({
          type: ADD_API_CREDENTIAL_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 400)
          setError(error.response?.data?.message);
        dispatch({ type: ADD_API_CREDENTIAL_FAILURE, payload: error });
      });
  };

  // API calling to edit API Credential
  let editApiCredential = (value) => {
    const data = {
      customerId: value.customerId,
      url: value.url,
      credentialTypeId: value.credentialTypeId,
      keyValue: value.keyValue,
      requestType: value.requestType,
    };

    dispatch({ type: EDIT_API_CREDENTIAL });
    API.put(`cargoWiseIntegration/credentials/${value.id}`, data)
      .then((response) => {
        handleCloseAddPopup();
        getApiCredentials();
        toast.success("API Credential Updated Successfully");
        dispatch({
          type: EDIT_API_CREDENTIAL_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 400)
          setError(error.response?.data?.message);
        dispatch({ type: EDIT_API_CREDENTIAL_FAILURE, payload: error });
      });
  };

  const getCustomerCargowise = () => {
    dispatch({ type: FETCH_CUSTOMER_WITH_CARGOWISE });
    API.get("cargoWiseIntegration/customers")
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMER_WITH_CARGOWISE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CUSTOMER_WITH_CARGOWISE_FAILURE, payload: err });
      });
  }

  // API calling to delete API Credential
  const handleDelete = () => {
    dispatch({ type: DELETE_API_CREDENTIAL });
    API.delete(`cargoWiseIntegration/credentials/${getDeleteApiCredential.id}`)
      .then((response) => {
        setDeletePopup(false);
        getApiCredentials();
        toast.success("API Credential Deleted Successfully");
        dispatch({
          type: DELETE_API_CREDENTIAL_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: DELETE_API_CREDENTIAL_FAILURE, payload: error });
        if (error.response.status === 400) {
          setError(error.response?.data?.message);
        }
      });
  };

  //Open Delete Api Credential Dialog
  const handleDeletePopup = (apiCredential) => {
    setDeleteApiCredential(apiCredential);
    setDeletePopup(true);
    setError("");
  };

  //Open Add Api Credential Dialog
  const handleAddPopup = (apiCredential) => {
    getCustomerCargowise();
    if (apiCredential) {
      const data = {
        id: apiCredential.id,
        customerId: apiCredential.customers.id,
        url: apiCredential.url,
        credentialTypeId: apiCredential.credentialTypeId,
        keyValue: apiCredential.keyValue,
        requestType: apiCredential.requestType
      };
      setEdit(true);
      setEditApiCredential(data);
    }
    setOpenAddPopup(true);
  };

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
    setTimeout(() => {
      setEdit(false);
      setEditApiCredential(schema.addApiIntegrationSchema);
      formik.handleReset();
    },500)
    setError("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getEditApiCredential,
    validationSchema: getEdit
      ? validationSchema.editApiCredentialValidationSchema
      : validationSchema.addApiCredentialValidationSchema,
    onSubmit: (value) => {
      getEdit ? editApiCredential(value) : addApiCredential(value);
    },
  });

  const handleSorting = (event, property) => {
    const isAsc = orderBy === property.sortTitle && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.sortTitle);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  // Debounce the search after 1 second
  useDebouncedEffect(
    () => {
      setDebounceSearch(search);
    },
    1000,
    [search]
  );

  useEffect(() => {
    getApiCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(0);
    if (page === 0) {
      getApiCredentials();
    }
  }, [debounceSearch]);

  useEffect(() => {
    if (state?.apiCredential?.credentialTypesData === null) {
      dispatch({ type: GET_CREDENTIAL_TYPES });
      API.get("cargoWiseIntegration/credentialTypes")
        .then((response) => {
          dispatch({
            type: GET_CREDENTIAL_TYPES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CREDENTIAL_TYPES_FAILURE, payload: error });
        });
    }
  }, []);

  return (
    <>
      <Header />
      <div className={classes.ApiListingWrapper}>
        <div className="setting-page wrapper">
          <div className="inner-page">
            <Typography variant="h1">Settings</Typography>
            <div className="setting-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar />
              </div>
              <div className="right-content">
                <div className="white-card right-content-inner">
                  <div className={classes.innerPageTopBlock}>
                    <div className="left-block">
                      <Typography variant="h1">API Credential Management</Typography>
                    </div>
                    <div className="right-block">
                      <div className="right-block-inner">
                        <div className="search-wrapper">
                          <div className="form-gourp">
                            <TextField
                              id="search-request"
                              placeholder="Search by Customer"
                              variant="outlined"
                              type="search"
                              className="search-field"
                              InputProps={{
                                endAdornment: <img src={SearchIcon} alt="Search"/>,
                              }}
                              value={search}
                              onChange={handleSearch}
                            />
                          </div>
                        </div>
                        <div className="modal-wrapper">
                          <div className="btn-wrapper">
                            <AddEditApiCredential
                              handleClickOpen={() => handleAddPopup()}
                              handleClose={handleCloseAddPopup}
                              open={openAddPopup}
                              formik={formik}
                              isEdit={getEdit}
                              error={err}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TableListing
                    handleOpen={(apiCredential) => handleDeletePopup(apiCredential)}
                    handleEdit={(editApiCredential) => handleAddPopup(editApiCredential)}
                    handleSorting={(e, property) => handleSorting(e, property)}
                    orderBy={orderBy}
                    order={order}
                    page={page}
                    handleChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <CustomDeletePopup
                    open={openDeletePopup}
                    handleClose={() => setDeletePopup(false)}
                    handleDelete={(deleteApiCredential) => handleDelete(deleteApiCredential)}
                    deletedMsg={`Are you sure to delete the ${getDeleteApiCredential?.apiCredentialTypes?.name} for ${getDeleteApiCredential?.customers?.name}?`}
                    loading={state?.apiCredential?.deletingApiCredential}
                    error={err}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ApiCredential;
