import { useState } from "react";
import {
    TableCell,
    TableRow,
    Collapse,
    Table,
    TableBody,
} from "@material-ui/core";
import { integrationApiPermissions } from "@utils/constant";

function Row(props) {
    const [open, setOpen] = useState(false);
    const { row, unique } = props;
    return (
        <>
            <TableRow onClick={() => setOpen(!open)} key={unique}>
                <TableCell
                    className="nameToken"
                >
                    {row.name ? `${row.name}(${row.token})` : "-"}
                </TableCell>
                <TableCell className="permission">
                    -
                </TableCell>
                <TableCell className="integrationPermission">
                    -
                </TableCell>

            </TableRow>
            <TableRow className="sub-row">
                <TableCell
                    className="MuiTableSubRoW"
                    colSpan={3}
                >
                    <Collapse in={open}>
                        <Table stickyHeader aria-label="simple table">
                            {row?.accessPermissions?.map((item, index) => {
                                return (item.integrationApiPermissionId !== integrationApiPermissions.NO_ACCESS &&
                                    <TableBody key={index}>
                                        <TableRow>
                                            <TableCell className="nameToken"></TableCell>
                                            <TableCell className="permission" key={item.permissionName}>{item.permissionDescription}</TableCell>
                                            <TableCell className="integrationPermission" key={item.integrationApiPermissionName}>{item.integrationApiPermissionDescription}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                );
                            })}
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;
