import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem, FormHelperText, Checkbox, ListItemText
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Loader from "@components/loader";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { AddGeofencePopupStyle } from "./style";
import {ExpandMore} from "@material-ui/icons";
import {geofenceCategory} from "@utils/constant";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";

function AddGeofencePopup(props) {
  const classes = AddGeofencePopupStyle();
  const materilClasses = materialCommonStyles();
  const [scroll] = useState("body");
  const [state] = useStore();

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.customModal}
        scroll={scroll}
      >
        <form
          noValidate
          autoComplete="off"
          className={classes.customForm}
          onSubmit={props.formik.handleSubmit}
        >
          <div className="close-modal">
            <img src={closeIcon} alt="Close" onClick={props.handleClose} />
          </div>
          {props.error && <Alert severity="error">{props.error}</Alert>}
          <DialogTitle id="alert-dialog-title">
            Add Geofence
          </DialogTitle>
          <DialogContent>
            <Loader loading={state?.geofence.loadingGeofenceList} />
            <Loader loading={state?.geofence.loadingAddGeofence} />
            <div className="form-row">
              <div className="form-gourp">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Name</label>
                  <TextField
                    id="name"
                    name="name"
                    placeholder="Name"
                    variant="outlined"
                    type="text"
                    onChange={props.formik.handleChange("name")}
                    value={props.formik.values.name || ""}
                  />
                </FormControl>
              </div>
              <div className="form-gourp">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Geofences</label>
                  <Select
                    labelId="geofence"
                    label="geofence"
                    displayEmpty
                    className={materilClasses.customSelect}
                    MenuProps={{
                      classes: { paper: materilClasses.customSelect + " fixedScrollMenu"},
                    }}
                    onChange={props.formik.handleChange("geofenceId")}
                    value={props.formik.values.geofenceId}
                    multiple
                    error={
                      props.formik.touched.geofenceId &&
                      Boolean(props.formik.errors.geofenceId)
                    }
                    helperText={
                      props.formik.touched.geofenceId &&
                      props.formik.errors.geofenceId
                    }
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <>Select Geofence</>;
                      }

                      return state?.geofence?.geofenceListData
                        ?.filter((m) => selected.includes(m.id))
                        .map((m) => m.name)
                        .join(",");
                    }}
                    IconComponent={() => <ExpandMore />}
                  >
                    <MenuItem key="default" value={""} disabled>
                      Select Geofence
                    </MenuItem>
                    {state?.geofence?.loadingGeofenceList ? (
                      <MenuItem>Loading...</MenuItem>
                    ) : (
                      state?.geofence?.geofenceListData?.length > 0 ?
                        (state?.geofence?.geofenceListData?.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item.id}
                              className="custom-checkbox custom-Multicheckbox"
                            >
                              <Checkbox
                                checked={props.formik.values.geofenceId.indexOf(item.id) > -1}
                                icon={<img src={uncheckedIcon} alt="CheckBox" />}
                                checkedIcon={<img src={checkedIcon} alt="CheckBox" />}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                        );
                      })):(
                        <MenuItem key="noData" disabled className={"enable-menu"}>No Data</MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText className="error-text">
                    {props.formik.touched.geofenceId && props.formik.errors.geofenceId}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="form-row">
              <div className="form-gourp">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">City</label>
                  <Select
                    id="city"
                    displayEmpty
                    className={materilClasses.customSelect}
                    MenuProps={{
                      classes: { paper: materilClasses.customSelect },
                    }}
                    onChange={props.formik.handleChange("city")}
                    value={props.formik.values.city}
                    IconComponent={() => <ExpandMore />}
                  >
                    <MenuItem value={""}>
                      Select City
                    </MenuItem>
                    {state?.geofence?.loadingGeofenceCityList ? (
                      <MenuItem>Loading...</MenuItem>
                    ) : (
                      state?.geofence?.geofenceCityListData?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.city}
                          >
                            {item.city}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="form-gourp">
                &nbsp;
              </div>
            </div>
            <div className="form-row">
              <div className="form-gourp">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Category</label>
                  <Select
                    id="category"
                    displayEmpty
                    className={materilClasses.customSelect}
                    MenuProps={{
                      classes: { paper: materilClasses.customSelect },
                    }}
                    onChange={props.formik.handleChange("category")}
                    value={props.formik.values.category}
                    IconComponent={() => <ExpandMore />}
                  >
                    <MenuItem value={""}>
                      Select Category
                    </MenuItem>
                    {
                      geofenceCategory.map((item, index) => {
                        return (
                          <MenuItem
                            key={item.key}
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="form-gourp">
                &nbsp;
              </div>
            </div>
          </DialogContent>
          <DialogActions className="bottom-button-block">
            <Button
              className="primary-btn gray-border-btn"
              color="inherit"
              disableElevation
              onClick={props.handleClose}
            >
              CANCEL
            </Button>
            <Button
              className="orange-btn primary-btn"
              color="inherit"
              disableElevation
              type="submit"
            >
              ADD GEOFENCE
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AddGeofencePopup;
