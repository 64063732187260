import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {TextField, Typography} from "@material-ui/core";
import { useFormik } from "formik";

import { useDebouncedEffect } from "@hooks/debounceEffect";
import { useStore } from "@store/store";
import SearchIcon from "@assets/images/search.svg";
import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAILURE,
} from "@utils/actionTypes";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";
import { uploadImage } from "@utils/commonFunctions";
import API from "@services/axios";
import TableListing from "./../../table-listing";
import AddCto from "./../../add-cto";
import { DeletedStyle } from "./style";
import {
  ADD_CTO,
  ADD_CTO_FAILURE,
  ADD_CTO_SUCCESS,
  EDIT_CTO,
  EDIT_CTO_FAILURE,
  EDIT_CTO_SUCCESS,
  FETCH_CTO,
  FETCH_CTO_FAILURE,
  FETCH_CTO_SUCCESS,
  RESTORE_CTO,
  RESTORE_CTO_FAILURE,
  RESTORE_CTO_SUCCESS
} from "@utils/actionTypes";
  import { rowsPerPageVal, geofenceCategoryEnum } from "@utils/constant";

function DeletedCto() {
  const classes = DeletedStyle();
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [getEditCto, setEditCto] = useState(schema.addCTOSchema);
  const [getEdit, setEdit] = useState(false);
  const [debounceSearch, setDebounceSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [search, setSearch] = useState("");
  const [err, setError] = useState("");
  const [state, dispatch] = useStore();
  const [image, setImage] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("city");
  const isFirstRender = useRef(true);

  // API calling to get list of cities
  let getCto = () => {
    const params = {
      ...(!!search ? { search } : {}),
      isDeleted: 1,
      page: page + 1,
      size: rowsPerPage,
      ...(!!order ? { order } : {}),
      ...(!!orderBy ? { orderBy } : {}),
    };

    dispatch({ type: FETCH_CTO });

    API.get("/ctos", { params })
      .then((response) => {
        dispatch({
          type: FETCH_CTO_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log("Error occurred:", error); // Log the error for debugging
        dispatch({ type: FETCH_CTO_FAILURE, payload: error });
      });
  };

  // API calling to add toll location
  let addCto = (value) => {
    dispatch({ type: ADD_CTO });
    API.post("ctos", value)
      .then((response) => {
        handleCloseAddPopup();
        getCto();
        toast.success("CTO Added Successfully");
        dispatch({
          type: ADD_CTO_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        setError(error.response?.data?.message);
        dispatch({ type: ADD_CTO_FAILURE, payload: error });
      });
  };

  // API calling to edit cto
  let editCto = (value) => {
    dispatch({ type: EDIT_CTO });
    API.put(`ctos/${value.id}`, value)
      .then((response) => {
        handleCloseAddPopup();
        getCto();
        toast.success("CTO Edited Successfully");
        dispatch({
          type: EDIT_CTO_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        setError(error.response?.data?.message);
        dispatch({ type: EDIT_CTO_FAILURE, payload: error });
      });
  };

  useEffect(() => {
    getCto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(0);
    if (page === 0) {
      getCto();
    }
  }, [debounceSearch]);

  useDebouncedEffect(
    () => {
      setDebounceSearch(search);
    },
    1000,
    [search]
  );

  const handleAddPopup = (user) => {
    if (state?.common?.countriesData === null) {
      dispatch({ type: GET_CITIES });
      API.get("master/cities")
        .then((response) => {
          dispatch({
            type: GET_CITIES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CITIES_FAILURE, payload: error });
        });
    }

    if (user) {
      setEdit(true);
      API.get(`ctos/${user.id}`)
        .then((response) => {
          setEditCto({
            id: response?.data?.data?.id,
            name: response?.data?.data?.name,
            cityId: response?.data?.data?.cities?.id,
            location: response?.data?.data?.location,
            latitude: response?.data?.data?.latitude,
            longitude: response?.data?.data?.longitude,
            logo: "",
            viewPhoto: response?.data?.data?.logo,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setOpenAddPopup(true);
  };

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
    setEdit(false);
    setEditCto(schema.addCTOSchema);
    setError("");
    setImage(null);
    formik.handleReset();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getEditCto,
    validationSchema: getEdit
      ? validationSchema.editCtoValidationSchema
      : validationSchema.addCtoValidationSchema,
    onSubmit: (value) => {
      let data = {
        id: value.id,
        name: value.name,
        cityId: parseInt(value.cityId),
        location: value.location,
        latitude: value.latitude,
        isDeleted: 0,
        longitude: value.longitude,
      };
      if (image) {
        dispatch({
          type: IMAGE_UPLOAD,
        });
        uploadImage(image, image.target.files[0]?.type, "cto-images")
          .then((res) => {
            data = {
              ...data,
              ...(!!res.data.fileName ? { logo: res.data.fileName } : {}),
            };
            getEdit ? editCto(data) : addCto(data);
            dispatch({
              type: IMAGE_UPLOAD_SUCCESS,
            });
          })
          .catch((err) => {
            dispatch({
              type: IMAGE_UPLOAD_FAILURE,
            });
            toast.error("Image Uploading Failed");
          });
      } else {
        editCto(data);
      }
    },
  });

  const handleRestore = (id) => {
    dispatch({ type: RESTORE_CTO });
    API.put(`ctos/${id}/restore`)
      .then((response) => {
        getCto();
        dispatch({
          type: RESTORE_CTO_SUCCESS,
        });
        toast.success("CTO Restored Successfully");
      })
      .catch((error) => {
        setError(error.response?.data?.message);
        dispatch({ type: RESTORE_CTO_FAILURE, payload: error });
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  const handleSorting = (event, property) => {
    const isAsc = orderBy === property.sortTitle && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.sortTitle);
  };

  const handleImage = (event, fileName, defaultText) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      document.getElementById(fileName).innerHTML = file.name;
      setImage(event);
      formik.setFieldValue("logo", file);
    } else {
      document.getElementById(fileName).innerHTML = defaultText;
    }
  };

  const handleMissingGeofenceMsg = (itemList) => {
    let hasEntrance = false;
    let hasLoadingBay = false;
    let msg = "";

    if (itemList.length > 0) {
      for(let i= 0; i < itemList.length; i++) {
        if (itemList[i].geofenceCategory === geofenceCategoryEnum.LOADING_BAY) {
          hasLoadingBay = true;
        }
        if (itemList[i].geofenceCategory === geofenceCategoryEnum.ENTRANCE) {
          hasEntrance = true;
        }
        if (hasEntrance && hasLoadingBay) {
          break;
        }
      }
    }

    if (!hasEntrance && !hasLoadingBay) {
      msg = `Missing linked Geofence: ${geofenceCategoryEnum.LOADING_BAY} & ${geofenceCategoryEnum.ENTRANCE}. This may result in automatic status updates not working correctly.`;
    } else if (!hasEntrance) {
      msg = `Missing linked Geofence: ${geofenceCategoryEnum.ENTRANCE}. This may result in automatic status updates not working correctly.`;
    } else if (!hasLoadingBay) {
      msg = `Missing linked Geofence: ${geofenceCategoryEnum.LOADING_BAY}. This may result in automatic status updates not working correctly.`;
    }

    return msg;
  }

  return (
    <>
      <div className={classes.tabHeadingRow}>
        <div className={classes.modalWrapper}>
          <div className={classes.searchWrapper}>
            <div className="form-gourp">
              <TextField
                placeholder="Search CTO"
                variant="outlined"
                type="search"
                InputProps={{
                  endAdornment: <img src={SearchIcon} alt="Search"/>,
                }}
                onChange={handleSearch}
                value={search}
              />
            </div>
          </div>
          <AddCto
            handleClickOpen={() => handleAddPopup()}
            handleClose={handleCloseAddPopup}
            open={openAddPopup}
            formik={formik}
            isEdit={getEdit}
            error={err}
            handleImage={handleImage}
          />
        </div>
        <div className="form-gourp">
          <Typography variant="h1">CTO Management</Typography>
        </div>
      </div>
      <TableListing
        page={page}
        handleEdit={(editCto) => handleAddPopup(editCto)}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleSorting={(e, property) =>
          handleSorting(e, property)
        }
        hasBtnRestore={true}
        handleRestore={(id) => handleRestore(id)}
        orderBy={orderBy}
        order={order}
        handleMissingGeofenceMsg={handleMissingGeofenceMsg}
        showToolTip={false}
      />
    </>
  );
}

export default DeletedCto;
