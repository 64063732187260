import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Select,
    MenuItem,
    FormHelperText
} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpandMore } from "@material-ui/icons";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { Alert } from "@material-ui/lab";

import closeIcon from "@assets/images/close.svg";
import { useStore } from "@store/store";
import { AddPopupStyle } from "./style";
import { sourceId } from "@utils/constant";

function Popup(props) {
    const classes = AddPopupStyle();
    const [scroll] = useState("body");
    const materilClasses = materialCommonStyles();
    const [state] = useStore();

    return (
        <>
            <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
                <div className="close-modal">
                    <img src={closeIcon} alt="Close" onClick={props.handleClose} />
                </div>
                <form
                    noValidate
                    autoComplete="off"
                    className={classes.customForm}
                    onSubmit={props.formik.handleSubmit}
                >
                    {props.error && <Alert severity="error">{props.error}</Alert>}
                    <DialogTitle> {"Generate"} New Token</DialogTitle>
                    <DialogContent>
                        <div className="form-row">
                            <div className="form-group">
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <label className="label-text">Token Name</label>
                                    <TextField
                                        id="tokenName"
                                        name="tokenName"
                                        placeholder="Token name"
                                        variant="outlined"
                                        autoComplete="off"
                                        type="text"
                                        value={props.formik.values.tokenName}
                                        onChange={props.formik.handleChange}
                                        error={
                                            props.formik.touched.tokenName &&
                                            Boolean(props.formik.errors.tokenName)
                                        }
                                        helperText={
                                            props.formik.touched.tokenName && props.formik.errors.tokenName
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <Accordion square={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="permission-title">Permission</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="permission">
                                            <ul className="permission-list">
                                                {
                                                    props?.data?.permissionIntegrationList?.map((item, index) => {
                                                        return (
                                                            <li className="permission-list-item" key={index}>
                                                                <div>
                                                                    <Typography className="permission-title">{item.description}</Typography>
                                                                    <div className="sub-title">
                                                                        <p>
                                                                            Get list, detail, create and delete
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="permission-wrapper">
                                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                                        <Select
                                                                            id={item.name}
                                                                            name={item.name}
                                                                            variant="outlined"
                                                                            autoComplete="off"
                                                                            value={props.formik.values[item.name]}
                                                                            onChange={props.formik.handleChange}
                                                                            error={
                                                                                props.formik.touched[item.name] &&
                                                                                Boolean(props.formik.errors[item.name])
                                                                            }
                                                                            helperText={
                                                                                props.formik.touched[item.name] && props.formik.errors[item.name]
                                                                            }
                                                                            className={materilClasses.customSelect}
                                                                            MenuProps={{
                                                                                classes: { paper: materilClasses.customSelect },
                                                                            }}
                                                                            IconComponent={() => <ExpandMore />}
                                                                        >
                                                                            {props?.data?.integrationApiPermissionList?.map((permission, index) =>
                                                                                <MenuItem key={index} value={permission.id}>{permission.description}</MenuItem>)
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="bottom-button-block">
                        <Button
                            className="primary-btn gray-border-btn"
                            color="inherit"
                            disableElevation
                            underlinenone="true"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="orange-btn primary-btn"
                            color="inherit"
                            disableElevation
                            underlinenone="true"
                            type="submit"
                            disabled={state.customer.generatingToken}
                        >
                            {state.customer.generatingToken ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                "Generate Token"
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
export default Popup;
