import React, {useEffect, useRef, useState} from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle, TextField, Typography,
} from "@material-ui/core";
import { useStore } from "@store/store";
import { toast } from "react-toastify";
import API from "@services/axios";
import { useDebouncedEffect } from "@hooks/debounceEffect";
import validationSchema from "@utils/validationSchemas";
import { schema } from "@utils/schemas";

import {
  FETCH_GEOFENCE,
  FETCH_GEOFENCE_SUCCESS,
  FETCH_GEOFENCE_FAILURE,
  ADD_GEOFENCE,
  ADD_GEOFENCE_SUCCESS,
  ADD_GEOFENCE_FAILURE,
  DELETE_GEOFENCE,
  DELETE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_FAILURE,
  FETCH_GEOFENCE_LIST,
  FETCH_GEOFENCE_LIST_SUCCESS,
  FETCH_GEOFENCE_LIST_FAILURE,
  FETCH_GEOFENCE_CITY_LIST,
  FETCH_GEOFENCE_CITY_LIST_SUCCESS,
  FETCH_GEOFENCE_CITY_LIST_FAILURE
} from "@utils/actionTypes";

import closeIcon from "@assets/images/close.svg";
import { TableListingPopupStyle } from "./style";
import TableListing from "../table-listing";
import AddGeofence from "./../add-geofence";
import SearchIcon from "@assets/images/search.svg";
import { useFormik } from "formik";
import { rowsPerPageVal } from "@utils/constant";
import DeletePopup from "@components/deletePopup";


function TableListGeoFencePopup(props) {
  const classes = TableListingPopupStyle();
  const [state, dispatch] = useStore();
  const [scroll] = useState("body");
  const [openAddGeofencePopup, setOpenAddGeofencePopup] = useState(false);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [debounceSearch, setDebounceSearch] = useState("");
  const [deleteGeofence, setDeleteGeofence] = useState({});
  const [geofenceId, setGeofenceId] = useState("");
  const [addGeofence, setAddGeofence] = useState(schema.addGeofenceSchema);
  const [err, setError] = useState("");
  const isFirstRender = useRef(true);

  /** Add Geofence dialog **/
  const getGeofenceCityList = () => {
    dispatch({ type: FETCH_GEOFENCE_CITY_LIST });
    API.get(`master/cities/geofences`)
      .then((response) => {
        dispatch({
          type: FETCH_GEOFENCE_CITY_LIST_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_GEOFENCE_CITY_LIST_FAILURE, payload: err });
      });
  }

  const handleAddGeofencePopup = () => {
    setOpenAddGeofencePopup(true);
    const params = {
      ctoId: props.ctoId,
      name: "",
      city: "",
      category: "",
      geofenceId: []
    }
    getGeofenceCityList();
    getFilteredGeofenceList(params);
  }
  const handleCloseAddGeofencePopup = () => {
    setOpenAddGeofencePopup(false);
    setAddGeofence(schema.addGeofenceSchema);
    setError("");
    formik.handleReset();
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: addGeofence,
    validationSchema: validationSchema.addGeofenceValidationSchema,
    onSubmit: (value) => {
      //API Call Add Geofence
      dispatch({ type: ADD_GEOFENCE });
      API.post(`ctos/${props.ctoId}/geofence`, {
        placeIds: value.geofenceId
      })
        .then((response) => {
          handleCloseAddGeofencePopup();
          toast.success("Geofences linked successfully");
          dispatch({
            type: ADD_GEOFENCE_SUCCESS,
            payload: response.data,
          });
          getGeofences();
          props.getCto();
        })
        .catch((err) => {
          dispatch({ type: ADD_GEOFENCE_FAILURE, payload: err });
        });
    }
  });

  const getFilteredGeofenceList = (item) => {
    const params = {
      name: item.name,
      city: item.city,
      category: item.category
    };

    //Call Api to filter the Geofence List
    dispatch({ type: FETCH_GEOFENCE_LIST });
    API.get(`ctos/${props.ctoId}/geofenceFilter`, {params})
      .then((response) => {
        dispatch({
          type: FETCH_GEOFENCE_LIST_SUCCESS,
          payload: response.data.data,
        });
        formik.setValues({ name: formik.values.name,
          city: formik.values.city,
          category: formik.values.category,
          geofenceId: []
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_GEOFENCE_LIST_FAILURE, payload: err });
      });
  }

  /** List Geofence **/
  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleDeletePopup = (item, geofenceId) => {
    setDeleteGeofence(item);
    setGeofenceId(geofenceId);
    setOpenDeletePopup(true);
    setError("");
  };
  const handleSorting = (event, property) => {
    const isAsc = orderBy === property.sortTitle && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.sortTitle);
  };

  // API calling to get list of Geofences
  let getGeofences = () => {
    const params = {
      page: page + 1,
      size: rowsPerPage,
      ...(!!debounceSearch ? { search: debounceSearch } : {}),
      ...(!!order ? { order } : {}),
      ...(!!orderBy ? { orderBy } : {}),
    };
    dispatch({ type: FETCH_GEOFENCE });
    API.get(`ctos/${props.ctoId}/geofences`, { params })
      .then((response) => {
        dispatch({
          type: FETCH_GEOFENCE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_GEOFENCE_FAILURE, payload: err });
      });
  };

  // API calling to delete Geofence
  const handleDelete = () => {
    dispatch({ type: DELETE_GEOFENCE });
    API.delete(`ctos/${props.ctoId}/geofence/${geofenceId}`)
      .then((response) => {
        setOpenDeletePopup(false);
        getGeofences();
        props.getCto();
        toast.success("Geofence Deleted Successfully");
        dispatch({
          type: DELETE_GEOFENCE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        setError("You can't delete this record");
        dispatch({ type: DELETE_GEOFENCE_FAILURE, payload: error });
      });
  };

  // Debounce the search after 1 second
  useDebouncedEffect(
    () => {
      setDebounceSearch(search);
    },
    1000,
    [search],
  );

  useEffect(() => {
    if (props.ctoId) {
      getGeofences();
    }
  }, [page, rowsPerPage, order, orderBy, props.ctoId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(0);
    if (page === 0) {
      getGeofences();
    }
  }, [debounceSearch]);

  // Debounce the filter after 1 second
  useDebouncedEffect(
    () => {
      const params = {
        ctoId: props.ctoId,
        name: formik.values.name,
        city: formik.values.city,
        category: formik.values.category,
        geofenceId: []
      };
      formik.setTouched({
        ...formik.touched,
        geofenceId: false,
      });

      getFilteredGeofenceList(params);
    },
    1000,
    [formik.values.name, formik.values.city, formik.values.category],
  );

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.customModal}
        scroll={scroll}
      >
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose} />
        </div>
        <DialogTitle id="alert-dialog-title">GEOFENCES</DialogTitle>
        <DialogContent>
          <div className={classes.tabHeadingRow}>
            <div className={classes.modalWrapper}>
              <div className={classes.searchWrapper}>
                <div className="form-gourp">
                  <TextField
                    placeholder="Search by Name/City"
                    variant="outlined"
                    type="search"
                    InputProps={{
                      endAdornment: <img src={SearchIcon} alt="Search"/>,
                    }}
                    onChange={handleSearch}
                    value={search}
                  />
                </div>
              </div>
              <AddGeofence
                handleClickOpen={() => handleAddGeofencePopup()}
                handleClose={handleCloseAddGeofencePopup}
                open={openAddGeofencePopup}
                formik={formik}
                error={err}
              />
            </div>
            <div className="form-gourp">&nbsp;</div>
          </div>
          <TableListing
            page={page}
            handleOpen={(place, geofenceId) => handleDeletePopup(place, geofenceId)}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleSorting={(e, property) =>
              handleSorting(e, property)
            }
            orderBy={orderBy}
            order={order}
          />
          <DeletePopup
            open={openDeletePopup}
            handleClose={() => setOpenDeletePopup(false)}
            handleDelete={() => handleDelete()}
            deleteUser={deleteGeofence.name}
            loading={state.geofence?.loadingDeleteGeofence}
            error={err}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TableListGeoFencePopup;
