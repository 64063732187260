import {
  FETCH_API_CREDENTIAL,
  FETCH_API_CREDENTIAL_SUCCESS,
  FETCH_API_CREDENTIAL_FAILURE,
  ADD_API_CREDENTIAL,
  ADD_API_CREDENTIAL_SUCCESS,
  ADD_API_CREDENTIAL_FAILURE,
  EDIT_API_CREDENTIAL,
  EDIT_API_CREDENTIAL_SUCCESS,
  EDIT_API_CREDENTIAL_FAILURE,
  DELETE_API_CREDENTIAL,
  DELETE_API_CREDENTIAL_SUCCESS,
  DELETE_API_CREDENTIAL_FAILURE,
  FETCH_CUSTOMER_WITH_CARGOWISE,
  FETCH_CUSTOMER_WITH_CARGOWISE_SUCCESS,
  FETCH_CUSTOMER_WITH_CARGOWISE_FAILURE,
  GET_CREDENTIAL_TYPES,
  GET_CREDENTIAL_TYPES_SUCCESS,
  GET_CREDENTIAL_TYPES_FAILURE

} from "@utils/actionTypes";

const INIT_STATE = {
  loading: false,
  apiCredentialData: null,
  addingAPiCredential: false,
  addApiCredentialData: null,
  editingApiCredential: false,
  editApiCredentialData: null,
  loadingCustomerCargowise: false,
  customerCargowiseData: null,
  deletingApiCredential: false,
  deleteApiCredentialData: null,
  loadingCredentialTypes: false,
  credentialTypesData: null
};

const apiCredentialReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_API_CREDENTIAL:
      return { ...state, loading: true };
    case FETCH_API_CREDENTIAL_SUCCESS:
      return { ...state, apiCredentialData: action.payload, loading: false };
    case FETCH_API_CREDENTIAL_FAILURE:
      return { ...state, apiCredentialData: action.payload, loading: false };

    case ADD_API_CREDENTIAL:
      return { ...state, addingAPiCredential: true };
    case ADD_API_CREDENTIAL_SUCCESS:
      return { ...state, addApiCredentialData: action.payload, addingAPiCredential: false };
    case ADD_API_CREDENTIAL_FAILURE:
      return { ...state, addApiCredentialData: action.payload, addingAPiCredential: false };

    case EDIT_API_CREDENTIAL:
      return { ...state, editingApiCredential: true };
    case EDIT_API_CREDENTIAL_SUCCESS:
      return { ...state, editApiCredentialData: action.payload, editingApiCredential: false };
    case EDIT_API_CREDENTIAL_FAILURE:
      return { ...state, editApiCredentialData: action.payload, editingApiCredential: false };

    case DELETE_API_CREDENTIAL:
      return { ...state, deletingApiCredential: true };
    case DELETE_API_CREDENTIAL_SUCCESS:
      return { ...state, deleteApiCredentialData: action.payload, deletingApiCredential: false };
    case DELETE_API_CREDENTIAL_FAILURE:
      return { ...state, deleteApiCredentialData: action.payload, deletingApiCredential: false };

    case FETCH_CUSTOMER_WITH_CARGOWISE:
      return { ...state, loadingCustomerCargowise: true };
    case FETCH_CUSTOMER_WITH_CARGOWISE_SUCCESS:
      return { ...state, customerCargowiseData: action.payload, loadingCustomerCargowise: false };
    case FETCH_CUSTOMER_WITH_CARGOWISE_FAILURE:
      return { ...state, customerCargowiseData: action.payload, loadingCustomerCargowise: false };
    case GET_CREDENTIAL_TYPES:
      return { ...state, loadingCredentialTypes: true };
    case GET_CREDENTIAL_TYPES_SUCCESS:
      return {
        ...state,
        loadingCredentialTypes: false,
        credentialTypesData: action.payload
      }
    case GET_CREDENTIAL_TYPES_FAILURE:
      return {
        ...state,
        loadingCredentialTypes: false,
        credentialTypesData: null
      }
    default:
      return state;
  }
};
export default apiCredentialReducer;
