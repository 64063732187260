import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import {
  FETCH_DRIVERS,
  FETCH_DRIVERS_FAILURE,
  FETCH_DRIVERS_SUCCESS,
  GET_CITIES,
  GET_CITIES_FAILURE,
  GET_CITIES_SUCCESS,
} from "@utils/actionTypes";
import API from "@services/axios";
import { AssignDriverStyle } from "./style";

function AssignDriverPopup(props) {
  const classes = AssignDriverStyle();
  const materialClasses = materialCommonStyles();
  const [scroll] = useState("body");
  const [state, dispatch] = useStore();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && state.driver.gettingDrivers;

  useEffect(() => {
    if (props.open) {
      // Get Drivers From API
      dispatch({ type: FETCH_DRIVERS });
      API.get("drivers", {
        params: {
          orderBy: "driverName",
          order: "ASC",
          filter: {
            active: true,
            cityId: props.cityId ? props.cityId : undefined,
          },
        },
      })
        .then((response) => {
          dispatch({
            type: FETCH_DRIVERS_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: FETCH_DRIVERS_FAILURE, payload: error });
        });

      // Get City From API
      if (state.common.citiesData === null) {
        dispatch({ type: GET_CITIES });
        API.get("master/cities")
          .then((response) => {
            dispatch({
              type: GET_CITIES_SUCCESS,
              payload: response.data.data,
            });
          })
          .catch((error) => {
            dispatch({ type: GET_CITIES_FAILURE, payload: error });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.cityId]);

  useEffect(() => {
    if (state.driver.getDriversData !== null) {
      setOptions(state.driver.getDriversData?.rows);
    }
  }, [state.driver.getDriversData]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customModal}
      scroll={scroll}
    >
      <div className="close-modal">
        <img src={closeIcon} alt="Close" onClick={props.handleClose} />
      </div>
      {props.error && <Alert severity="error">{props.error}</Alert>}
      <DialogTitle id="alert-dialog-title">Assign Driver</DialogTitle>
      <form
        noValidate
        autoComplete="off"
        className={classes.customForm}
        onSubmit={props.formik.handleSubmit}
      >
        <DialogContent>
          <div className="form-row wrap-flex">
            <div className="form-group">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                disabled
              >
                <label className="label-text font-16">City</label>
                <Select
                  id="city"
                  name="cityId"
                  displayEmpty
                  className={materialClasses.customSelect}
                  MenuProps={{
                    classes: { paper: materialClasses.customSelect },
                  }}
                  value={props.cityId}
                  IconComponent={() => <ExpandMore />}
                >
                  <MenuItem
                    value={
                      props.cityId === undefined || props.cityId === null
                        ? ""
                        : props.cityId
                    }
                    disabled
                  >
                    Select a city
                  </MenuItem>
                  {state?.common?.citiesData?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="form-row wrap-flex">
            <div className="form-group">
              <FormControl variant="outlined" className={classes.autoComplete}>
                <label className="label-text font-16">Driver</label>
                <Autocomplete
                  popupIcon={<ExpandMore />}
                  className={materialClasses.autoComplete}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loading}
                  options={options}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    const id = value ? String(value.id) : "";
                    props.formik.setFieldValue("assignDriver", id);
                  }}
                  renderOption={(option) => (
                    <React.Fragment>
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        className={materialClasses.autoComplete}
                      >
                        {option.name}
                      </MenuItem>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      className="label-text"
                      placeholder={"Select driver"}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
                <FormHelperText className="error-text">
                  {props.formik.touched.assignDriver &&
                    props.formik.errors.assignDriver}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="bottom-button-block">
          <Button
            className="primary-btn gray-border-btn"
            color="inherit"
            disableElevation
            onClick={props.handleClose}
          >
            CANCEL
          </Button>
          <Button
            className="orange-btn primary-btn center-loading-indicator"
            color="inherit"
            disableElevation
            type="submit"
            disabled={state.jobPool.assigningDriver}
          >
            {state.jobPool.assigningDriver ? (
              <CircularProgress color="inherit" />
            ) : (
              "ASSIGN"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AssignDriverPopup;
