import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Header from "@components/header";
import { jobStatus, routes } from "@utils/constant";
import API from "@services/axios";
import EditBlock from "./edit-block";
import Tabbing from "./tabbing";
import { JobDetailStyle } from "./style";

function DetailPage() {
  const classes = JobDetailStyle();
  const { id } = useParams();
  const history = useHistory();
  // const location = useLocation();
  // const [jobName, setJobName] = useState("");
  const [jobsData, setJobsData] = useState({});
  // const [isRollbackMerge, setRollbackMerge] = useState(false);

  // useEffect(() => {
  //   if (location.state?.jobTypes?.name !== jobName)
  //     setJobName(location.state?.jobTypes?.name);
  //   // eslint-disable-next-line
  // }, [location.state?.jobTypes?.name]);

  // API calling to get list of job by ID
  let getJobs = () => {
    setJobsData(null);
    API.get(`jobs/${id}`)
      .then((response) => {
        // checkRollbackMerge();
        setJobsData(response.data.data);
      })
      .catch((error) => {
        setJobsData(error);
        if (error?.response?.status === 404) {
          history.push(routes.pageNotFound);
        }
      });
  };
  useEffect(() => {
    getJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // const checkRollbackMerge = async () => {
  //   try {
  //     const response = await API.get(`jobs/${id}/checkRollbackMerge`);
  //     const { isRollbackMerge } = response.data.data;
  //     if (isRollbackMerge) {
  //       setRollbackMerge(true);
  //     } else {
  //       setRollbackMerge(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const BackLink = () => {
    const previousLocation = document.referrer;
    let backToListingRoute = routes.dashboard;
    let backToListingText = "Back to dashboard";

    if (previousLocation) {
      if (previousLocation.includes(routes.jobListing) || previousLocation.includes(routes.jobPoolListing)) {
        if (jobsData) {
          if ([jobStatus.NOT_ASSIGNED, jobStatus.REJECTED].includes(jobsData?.jobStatuses?.name)) {
            backToListingText = "Back to job pool";
            backToListingRoute = routes.jobPoolListing;
          } else {
            backToListingText = "Back to jobs"
            backToListingRoute = routes.jobListing;
          }
        } else {
          backToListingText = "";
          backToListingRoute = "";
          return null;
        }
      } else {
        backToListingRoute = new URL(previousLocation).pathname;
        backToListingText = previousLocation.includes(routes.customerDetail) ? "Back to customers"
          : previousLocation.includes(routes.report) ? "Back to report"
          : previousLocation.includes(routes.driverDetail) ? "Back to drivers"
          : "Back to dashboard";
      }
    } else {
      if (jobsData) {
        if ([jobStatus.NOT_ASSIGNED, jobStatus.REJECTED].includes(jobsData?.jobStatuses?.name)) {
          backToListingText = "Back to job pool";
          backToListingRoute = routes.jobPoolListing;
        } else {
          backToListingText = "Back to jobs"
          backToListingRoute = routes.jobListing;
        }
      } else {
        backToListingText = "";
        backToListingRoute = "";
        return null;
      }
    }

    return (
      <Link to={backToListingRoute} className={classes.backToPage}>
        <ArrowBackIosIcon /> {backToListingText}
      </Link>
    );
  };

  return (
    <>
      <Header />
      <div className={classes.JobDetailWrapper}>
        <div className="wrapper job-detail-page">
          <div className="inner-page">
            <div className={classes.backLinkWrapper}>
              <BackLink />
            </div>
            <div className="detail-col-layout">
              <div className="left-block">
                <EditBlock
                  // setJobName={setJobName}
                  getJobs={getJobs}
                  jobsData={jobsData}
                  // isRollbackMerge={isRollbackMerge}
                  loadingJobs={jobsData === null && true}
                />
              </div>
              <div className="right-block">
                <Tabbing jobsData={jobsData} getJobs={getJobs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailPage;
